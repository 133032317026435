import React from 'react';
import { useTranslation } from 'react-i18next';
import YellowFin from '../YellowFin';

const MissedOpportunities = () => {
    const { t: translate } = useTranslation();
    const title = translate('Missed Opportunities');

    return (
        <div>
            <YellowFin
                dashUUID="da861bd1-dade-4234-a549-5ad831caec7d"
                title={title}
                reportUUID=""
                dashDivId="dashboardDiv"
                reportDivId="reportDiv"
                titleDescription="Missed Opportunities Dashboard"
            />
        </div>
    );
};

export default MissedOpportunities;
