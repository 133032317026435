import React, { useEffect, useRef } from 'react';
import { apis, request } from '../../httpUtil';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PageTitle from '../PageTitle';
import actions from '../../redux/actions';
import utils from '../../utils';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    yellowRoot: {
        '& app-dashboard > .dashContainer.activeDashboard': {
            padding: '1rem !important',
        },
    },
}));

const YellowFin = ({ dashUUID, title, reportUUID, dashDivId, reportDivId, clientOrgRef, titleDescription }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const t = utils.t;
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    const yellowfinInitialized = useRef(false);
    let yellowFinBaseUrl = "https://bi.coolrgroup.com/";
    const userData = useSelector(state => state.appReducer.userData);
    const { ClientName, ClientIds: tagsClientIds = '' } = userData && userData.tags ? userData.tags : 0;
    const clientsList = useSelector(state => state.appReducer.clientsList);
    const isMultiClientsAssigned = tagsClientIds.split(',').length > 1 && tagsClientIds.trim() !== '0';
    let multiClientNames = [];
    if (isMultiClientsAssigned) {
        const tagClientIdsArray = tagsClientIds.split(',').map(id => id.trim());
        for (const client of clientsList) {
            if (tagClientIdsArray.includes(client.Key.toString().trim())) {
                const cleanedValue = client.Value.replace(/\([^)]*\)/g, '').trim();
                multiClientNames.push(cleanedValue);
            }
        }
    }
    const isYellowFinScriptLoaded = () =>
        document.querySelector(`script[src="${yellowFinBaseUrl}JsAPI/v3"]`) !== null;

    const loadYellowFinScript = () => {
        return new Promise((resolve, reject) => {
            if (isYellowFinScriptLoaded()) {
                resolve();
                return;
            }
            const script = document.createElement('script');
            script.src = `${yellowFinBaseUrl}JsAPI/v3`;
            script.async = true;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error('Failed to load Yellowfin script'));
            document.body.appendChild(script);
        });
    };

    const initializeYellowfin = async (token) => {
        if (!yellowfinInitialized.current) {
            await window.yellowfin.init();
            await window.yellowfin.newSession(token, '');
            yellowfinInitialized.current = true;
        }
    };
    const loadContent = async () => {
        const dashboardDiv = document.getElementById(dashDivId);
        const reportDiv = document.getElementById(reportDivId);

        if (dashboardDiv) {
            dashboardDiv.innerHTML = '';
        }
        window.yellowfin.loadDashboard({
            dashboardUUID: dashUUID,
            element: dashboardDiv,
            showFilters: false,
            showExport: false,
            showToolbar: false,
            showInfo: false,
            showShare: false,
            filterValues: [
                {
                    filterId: 'ClientName',
                    valueList: isMultiClientsAssigned ? multiClientNames : [ClientName]
                }
            ]
        });


        if (reportDiv) {
            reportDiv.innerHTML = '';
        }
        window.yellowfin.loadReport({
            reportId: reportUUID,
            element: reportDiv,
        });
    };

    const fetchData = async () => {
        try {
            const reqParams = { clientOrgRef: clientOrgRef || "" };
            const response = await request({ url: apis.MissedOpportunities, params: reqParams, history, dispatch, jsonPayload: true });
            const token = response.data.securityToken;
            yellowFinBaseUrl = response.data.baseUrl;
            if (!token) {
                throw new Error('No token found');
            }
            await loadYellowFinScript();
            await initializeYellowfin(token);
            await loadContent();
        } catch (error) {
            console.error('Error initializing/loading Yellowfin content:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [dashUUID, reportUUID, dashDivId, reportDivId, clientOrgRef]);

    useEffect(() => {
        dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle titleHeading={t(title, tOpts)} titleDescription={t(titleDescription, tOpts)} icon='' title={t(title, tOpts)} classTitle="home-header-container" /> });
        utils.removeBackButton(dispatch);
        return () => {
            dispatch({
                type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: null
            });
        };
    }, []);

    return (
        <div className={classes.yellowRoot}  >
            <div id={dashDivId}></div>
        </div>
    );
};

export default YellowFin;