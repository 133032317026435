import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Tabs, Tab, IconButton, Tooltip, Badge } from "@material-ui/core";
import { InfoRounded, Info } from '@material-ui/icons';
import { getGridDateOperators } from "@mui/x-data-grid-premium";
import actions from "./../../redux/actions";
import FilterPanel from '../filterPanel';
import Ignore from './Ignore';
import InTransit from './InTransit';
import Inbox from './Inbox';
import Scheduled from './Scheduled';
import EditOrder from './EditOrder';
import dateUtils from '../../utils/date';
import enums from '../../utils/constants';
import { apis, request } from "../../httpUtil";
import { useHistory } from "react-router";
import OutletCard from '../Outlets/outletCard';
import utils from './../../utils';
import PageviewIcon from '@material-ui/icons/Pageview';
import { useTranslation } from "react-i18next";
import useMobile from '../../utils/useMobile';
import { UserContext } from '../../utils/orderContext';
import { useParams } from "react-router-dom"
import localizedDateFormat from "../LocalizedDatePicker";
import { Box } from "@mui/material";
import constants from "../../utils/constants";
const { t, dayjsFormatLocal } = utils;

const filterOperators = getGridDateOperators().filter(({ value }) =>
    !['not'].includes(value)
);

let tabList = [
    { icon: "todoList.png", iconSize: '30', iconPosition: "start", label: "Inbox", badge: false, toolTipTitle: "Orders that have latest image" },
    { icon: "scheduled.png", iconSize: '30', iconPosition: "start", label: "Scheduled", badge: false, toolTipTitle: "Order to be triggered" },
    { icon: "truck.png", iconSize: '30', iconPosition: "start", label: "In Transit", badge: false, toolTipTitle: "Orders already triggered" },
    { icon: "ignore.png", iconSize: '30', iconPosition: "start", label: "Ignore", badge: false, toolTipTitle: "Orders manually or automatically ignored for not having a latest image" }
];

const orderEnums = {
    inbox: 0,
    inTransit: 5484
}

function Replenishment(props) {
    const isMobileDevice = useMobile();
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(0);
    const comboData = useSelector(state => state.appReducer.multiClientComboData);
    const portalCombos = useSelector(state => state.appReducer.comboData);
    const filterValues = useSelector(state => state.appReducer.filterValues);
    const [outletCard, setOutletCard] = useState({ modal: false, data: "" });
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const userData = useSelector(state => state.appReducer.userData) || {};
    const { ScheduledOrderId: scheduledOrderId = 0, ClientId, ClientIds: tagsClientIds = '', RoleId } = userData?.tags || {};
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(Number(scheduledOrderId) > 0 ? 1 : 0);
    const locale = useSelector(state => state.appReducer.locale);
    const [userContext] = React.useContext(UserContext);
    const idOrderScheduled = Number(scheduledOrderId) > 0;
    const params = useParams();
    const { locationId, statusId } = params;
    const clientsList = useSelector(state => {
        const list = state.appReducer.clientsList || [];
        if (list.length) {
            return list.map(e => ({ LookupId: e.Key, DisplayValue: e.Value, CustomValue: e.Key }));
        }
    }) || [];
    const multiClientFilters = useSelector(state => state.appReducer.filters || []);
    const { IsSuperAdmin } = userData && userData.tags;
    const isAdminOrSuperAdmin = utils.isAdminORSuperAdmin(IsSuperAdmin);

    const format = utils.systemDateTimeFormat(true);
    const localizedFormat = localizedDateFormat({ columnType: 'date' });
    const girdColumns = ({ dateKey = "LastImageDateTime", caseKey = "TotalCase", valueKey = "TotalOrder", action = null, t, tOpts, isMobileDevice, DateFormat, orderSuggestionKey = "OrderSuggestion", DeliveryNotesCaption = '', onOutletCodeCellClick = () => { } } = {}) => [
        {
            id: 'ClientName', field: 'ClientName', headerName: t('Client Name', tOpts), flex: 0, minWidth: 150, type: "string"
        },
        {
            id: 'Code', field: 'Code', headerName: t('Outlet Code', tOpts), flex: 0, minWidth: (isMobileDevice.tablet ? 150 : isMobileDevice.mobile ? 120 : 150), valueGetter: (value) => value.row.Code, type: "string", hide: true, renderCell: ({ row, value }) => (
                <div className='d-flex justify-space-between align-center w-100'>
                    <div onClick={() => onOutletCodeCellClick({ row, defaultTab: 0 })}><Info color="primary" style={{ marginRight: '4px' }} />{value}</div>
                </div>
            ),
        },
        {
            id: 'LocationName', field: 'LocationName', headerName: t('Outlet Name', tOpts), flex: (isMobileDevice.tablet ? 0 : isMobileDevice.mobile ? 1 : 0), minWidth: (isMobileDevice.tablet ? 150 : isMobileDevice.mobile ? 130 : 200), valueGetter: (value) => value.row.LocationName ?? '', type: "string", valueFormatter: (item) => {
                return t(item.value, tOpts)
            }
        },
        { id: 'Street', field: 'Street', headerName: t('Street', tOpts), flex: 0, minWidth: (isMobileDevice.mobile ? 100 : 150), valueGetter: ({ row }) => row.Street, type: "string" },
        { id: 'City', field: 'City', headerName: t('City', tOpts), flex: 0, minWidth: 100, valueGetter: ({ row }) => row.City, type: "string" },
        { id: 'State', field: 'State', headerName: t('State', tOpts), flex: 0, minWidth: 100, valueGetter: ({ row }) => row.State, type: "string" },
        { id: 'PostalCode', field: 'PostalCode', headerName: t('Postal Code', tOpts), flex: 0, minWidth: 100, valueGetter: ({ row }) => row.PostalCode, type: "string" },
        {
            id: orderSuggestionKey, field: orderSuggestionKey, headerName: t('Order Suggestion', tOpts), type: 'date', filterOperators: localizedFormat, flex: 0, minWidth: 150, valueFormatter: (item) => {
                const date = dateUtils.dateParse(item.value);
                return dayjsFormatLocal({ value: date, format: format || enums.dateFormat.date, tOpts: tOpts, lang: null });
            }
        },
        {
            id: dateKey, field: dateKey, headerName: t('Order Scheduled', tOpts), type: 'date', filterOperators: localizedFormat, flex: 0, minWidth: (isMobileDevice.mobile ? 100 : 150), valueGetter: (value) => value.row[dateKey], valueFormatter: (item) => {
                const date = dateUtils.dateParse(item.value);
                return dayjsFormatLocal({ value: date, format: format || enums.dateFormat.date, tOpts: tOpts, lang: null });
            }
        },
        {
            id: "DeliveryNotes", field: 'DeliveryNotes', headerName: t(`${DeliveryNotesCaption || 'Future Delivery Dates'}`, tOpts), flex: 0, sortable: false, minWidth: 100, type: "string"
        },
        {
            id: 'ShareOfShelf', field: 'ShareOfShelf', headerName: t('Fill Level', tOpts), type: "number", flex: 0, filterable: false, sortable: false, minWidth: 100, valueGetter: (value) => value.row.ShareOfShelf, renderCell: (item) => {
                const { value } = item;
                let className = '';
                if (value >= 0 && value <= 50) {
                    className = 'location-reorder-fill-level-red';
                }
                if (value > 50 && value <= 70) {
                    className = 'location-reorder-fill-level-yellow';
                }
                if (value > 70 && value <= 100) {
                    className = 'location-reorder-fill-level-green';
                }
                return (
                    <div className={className}>{item.value || '-'}</div>
                )
            }
        },
        { id: caseKey, field: caseKey, headerName: t('Cases', tOpts), type: "number", flex: 0, minWidth: (isMobileDevice.tablet ? 150 : isMobileDevice.mobile ? 50 : 100), valueGetter: (value) => value.row[caseKey] },
        {
            id: valueKey, field: valueKey, headerName: t('Value', tOpts), type: "number", flex: 0, minWidth: (isMobileDevice.tablet ? 150 : isMobileDevice.mobile ? 70 : 75), valueGetter: (value) => value.row, valueFormatter: (item) => {
                const { Currency } = item.value;
                return Currency.replace('{0}', item.value[valueKey]);
            }
        },
        {
            id: 'MarketName', field: 'MarketName', headerName: t('Market', tOpts), flex: 0, minWidth: (isMobileDevice.mobile ? 100 : 150), type: "string", valueGetter: (value) => value.row.MarketName, valueFormatter: (item) => {
                return item.value || '-'
            }
        },
        {
            id: 'LocationTypeName', field: 'LocationTypeName', headerName: t('Channel', tOpts), flex: 0, minWidth: (isMobileDevice.mobile ? 100 : 150), type: "string", valueGetter: (value) => value.row.LocationTypeName, valueFormatter: (item) => {
                return item.value || '-'
            }
        },
        {
            id: 'ClassificationName', field: 'ClassificationName', headerName: t('Classification', tOpts), flex: 0, minWidth: (isMobileDevice.mobile ? 100 : 150), type: "string", valueGetter: (value) => value.row.ClassificationName, valueFormatter: (item) => {
                return item.value || '-'
            }
        },
        {
            id: 'LocationRoute', field: 'LocationRoute', headerName: t('Route', tOpts), flex: 0, minWidth: (isMobileDevice.mobile ? 100 : 150), type: "string", valueGetter: (value) => value.row.LocationRoute, valueFormatter: (item) => {
                return item.value || '-'
            }
        },
        {
            id: 'PreSellerRouteName', field: 'PreSellerRouteName', headerName: t('Pre Seller Route', tOpts), flex: 0, minWidth: (isMobileDevice.mobile ? 100 : 150), type: "string", valueGetter: (value) => value.row.PreSellerRouteName, valueFormatter: (item) => {
                return item.value || '-'
            }
        },
        {
            id: 'SalesRepName', field: 'SalesRepName', headerName: t('Sales Rep', tOpts), flex: 0, minWidth: (isMobileDevice.mobile ? 100 : 150), type: "string", valueGetter: (value) => value.row.SalesRepName, valueFormatter: (item) => {
                return item.value || '-'
            }
        },
        {
            id: 'Image', field: 'Image', headerName: t('Image', tOpts), type: "action", flex: 0, minWidth: (isMobileDevice.tablet ? 150 : isMobileDevice.mobile ? 50 : 150), filterable: false, sortable: false, valueGetter: (value) => value.row, renderCell: (params) => {
                return (
                    <IconButton className="button-outline" onClick={() => action?.(params)}>
                        <PageviewIcon />
                    </IconButton>
                )
            }
        },
        {
            id: "LastDeliveryDate", field: 'LastDeliveryDate', headerName: t(`Last Delivery Date`, tOpts), type: 'date', minWidth: 150, filterOperators: localizedFormat, flex: 0, valueGetter: ({ row }) => row.LastDeliveryDate, valueFormatter: (item) => {
                const date = dateUtils.dateParse(item.value);
                return dayjsFormatLocal({ value: date, format: format || enums.dateFormat.date, tOpts: tOpts, lang: null });
            }
        },
        {
            id: "LastOrderDate", field: 'LastOrderDate', headerName: t(`Last Order Date`, tOpts), type: 'date', minWidth: 150, filterOperators: localizedFormat, flex: 0, valueGetter: ({ row }) => row.LastOrderDate, valueFormatter: (item) => {
                const date = dateUtils.dateParse(item.value);
                return dayjsFormatLocal({ value: date, format: format || enums.dateFormat.date, tOpts: tOpts, lang: null });
            }
        },
        {
            id: 'FinalMoQ', field: 'FinalMoQ', headerName: t('MOQ', tOpts), flex: 0, minWidth: (isMobileDevice.mobile ? 80 : 120), type: "number", valueGetter: (value) => value.row.FinalMoQ, valueFormatter: (item) => {
                return item.value || '-'
            }
        },
        {
            id: 'FinalMoV', field: 'FinalMoV', headerName: t('MOV', tOpts), flex: 0, minWidth: (isMobileDevice.mobile ? 80 : 120), type: "number", valueGetter: (value) => value.row.FinalMoV, valueFormatter: (item) => {
                return item.value || '-'
            }
        },
        {
            id: 'LastMOVAndMOQDateTime', field: 'LastMOVAndMOQDateTime', headerName: t('Date Met MOQ/MOV', tOpts), flex: 0, minWidth: (isMobileDevice.mobile ? 80 : 150), type: "date", filterOperators: localizedFormat, valueGetter: (value) => value.row.LastMOVAndMOQDateTime, valueFormatter: (item) => {
                const date = dateUtils.dateParse(item.value);
                return dayjsFormatLocal({ value: date, format: format || enums.dateFormat.date, tOpts: tOpts, lang: null });
            }
        }
    ];

    const getMultiClientsFilter = async () => {
        const { success, data } = await request({ url: apis.GetMultiClientFilters, disableLoader: false, history, dispatch });
        dispatch({ type: actions.SET_MULTICLIENT_FILTERS, filters: success ? data : [] });
        dispatch({ type: actions.SET_FILTER_VALUES, filterValues: { uiClientIds: [Number(ClientId)], changedKey: 'uiClientIds' } });
    }

    useEffect(() => {
        utils.getFilteredLookups({ filterValues, tagsClientIds, portalCombos, dispatch, history });
    }, [filterValues, portalCombos])

    useEffect(() => {
        const isMultiClientsAssigned = tagsClientIds.split(',').length > 1 && tagsClientIds.trim() !== '0';
        if (idOrderScheduled) {
            tabList = [
                { icon: "scheduled.png", iconSize: '30', iconPosition: "start", label: "Scheduled", badge: false, toolTipTitle: "Order to be triggered" }
            ];
        }
        if (Number(locationId)) {
            tabList = tabList.filter(tab => tab.label === (Number(statusId) === orderEnums.inbox ? 'Inbox' : 'In Transit'));
            setActiveTab(Number(statusId) === orderEnums.inbox ? 0 : 2);
        }
        dispatch({ type: actions.OVERVIEW_RECORD_SELECT, overviewRecordSelect: { code: null } });
        dispatch({ type: actions.REPLENISHMENT_GRID_DATA, replenishmentGridData: { data: [] } });
        clearFilters();
        if (isMultiClientsAssigned) {
            getMultiClientsFilter();
        }
        return () => {
            dispatch({
                type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                    filterButton: null,
                    clear: clearFilters,
                    apply: null,
                    hidden: { search: true, operation: idOrderScheduled, export: idOrderScheduled, print: false, filter: true }
                }
            });
            utils.removeBackButton(dispatch);
        }
    }, []);

    useEffect(() => {
        const isMobile = !!((isMobileDevice.mobile && !isMobileDevice.tablet));
        const isExport = idOrderScheduled ? true : isMobile;
        const isMultiClients = tagsClientIds.split(',').length > 1 && tagsClientIds.trim() !== '0';
        if (isAdminOrSuperAdmin || !isMultiClients) {
            delete filtersConfig[0].config[0];
        }
        dispatch({
            type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                filterButton: <FilterPanel filtersConfig={filtersConfig} getData={null} />,
                printButtonName: t("Export to PDF", tOpts),
                clear: clearFilters,
                apply: applyGlobalFilter,
                hidden: { search: idOrderScheduled, filterable: idOrderScheduled, print: true, operation: idOrderScheduled, export: isExport }
            }
        });
    }, [comboData]);

    useEffect(() => {
        if (RoleId && constants.roles.storeOwner.includes(Number(RoleId)) && constants.portugalFranceClientIds.includes(Number(ClientId))) {
            const language = constants.portugalFranceClientIdsWithLanguages[Number(ClientId)];
            if (locale !== language) {
                i18n.changeLanguage(language);
                dispatch({ type: actions.SET_CURRENT_LOCALE, locale: language });
                window.location.reload();
            }
        }
    }, [RoleId])

    const handleChangeTab = (event, newValue) => {
        if (activeTab !== newValue) {
            dispatch({ type: 'SET_LOCATION_SEARCH', locationSearch: undefined });
            dispatch({ type: actions.OVERVIEW_RECORD_SELECT, overviewRecordSelect: { code: null } });
            dispatch({ type: actions.REPLENISHMENT_GRID_DATA, replenishmentGridData: { data: [] } });
            setActiveTab(newValue);
        }
    };

    const applyGlobalFilter = (e) => {
        setRefresh((val) => (val + 1));
    }

    function getTab(tab) {
        const components = {
            '0': <Inbox onClickItem={getVistaLocationRecord} isReorder={true} columns={girdColumns} globalFilter={filterValues} refresh={refresh} multiClientFilters={multiClientFilters} />,
            '1': <Scheduled onClickItem={getVistaLocationRecord} isReorder={false} columns={girdColumns} globalFilter={filterValues} refresh={refresh} multiClientFilters={multiClientFilters} />,
            '2': <InTransit onClickItem={getVistaLocationRecord} isReorder={false} columns={girdColumns} globalFilter={filterValues} refresh={refresh} multiClientFilters={multiClientFilters} />,
            '3': <Ignore onClickItem={getVistaLocationRecord} isReorder={false} columns={girdColumns} globalFilter={filterValues} refresh={refresh} multiClientFilters={multiClientFilters} />,
        }
        return components[tab + ''];
    }

    function addBadge(status, icon) {
        if (status)
            return <Badge badgeContent={1} color="secondary">{icon}</Badge>;
        else
            return icon;
    }

    function clearFilters(props) {
        const { resetHeaderFilters = false } = props || {};
        setRefresh((val) => (val + 1));
        dispatch({
            type: actions.SET_FILTER_VALUES, filterValues: {}
        });
        if (resetHeaderFilters) {
            const values = { Market: [], LocationClassification: [], LocationType: [], SalesPerson: [], RouteType: [] }
            dispatch({ type: actions.SET_MULTICLIENT_COMBO_DATA, comboData: values });
        }
    }

    const filtersConfig = [
        {
            name: t("Filters", tOpts),
            config: [
                {
                    name: "uiClientIds",
                    label: "Client",
                    type: "checkSelect",
                    titleMessage: 'Select & Unselect All',
                    multiSelect: true,
                    optionYesNo: true,
                    checkUncheck: true,
                    multiSelectLabel: 'Multiple Clients',
                    options: clientsList || [],
                    size: 'small',
                    hidden: false,
                    className: 'outlet-markets'
                },
                {
                    name: "marketIds",
                    label: "Market",
                    type: "select",
                    multiSelect: false,
                    checkUncheck: false,
                    options: comboData?.Market || [],
                    size: 'small',
                    hidden: false,
                    wrapText: true
                },
                {
                    name: "classificationIds",
                    label: "Classification",
                    type: "select",
                    multiSelect: false,
                    checkUncheck: false,
                    options: comboData?.LocationClassification || [],
                    size: 'small',
                    hidden: false,
                    wrapText: true
                },
                {
                    name: "channelIds",
                    label: "Channel",
                    type: "select",
                    multiSelect: false,
                    checkUncheck: false,
                    options: comboData?.LocationType || [],
                    size: 'small',
                    hidden: false,
                    wrapText: true
                },
                {
                    name: "DistributorId",
                    label: "Distributor",
                    type: "select",
                    multiSelect: false,
                    checkUncheck: false,
                    options: comboData?.Distributor || [],
                    size: 'small',
                    hidden: false,
                    wrapText: true
                },
                {
                    name: "managerSelectedValue",
                    label: "Primary Sales Rep",
                    type: "select",
                    multiSelect: false,
                    checkUncheck: false,
                    options: comboData?.SalesPerson || [],
                    size: 'small',
                    hidden: false,
                    wrapText: true
                },
                {
                    name: "routeSelectedValue",
                    label: "Route",
                    type: "select",
                    multiSelect: false,
                    checkUncheck: false,
                    options: comboData?.RouteType || [],
                    size: 'small',
                    hidden: false,
                    wrapText: true
                },
                {
                    name: "casesRange",
                    label: "Case Size",
                    type: "minmax",
                    checkUncheck: false,
                    size: 'small',
                    hidden: false
                },
                {
                    name: "orderRange",
                    label: "Order Size",
                    type: "minmax",
                    checkUncheck: false,
                    size: 'small',
                    hidden: false
                }
            ],
            openDefault: true
        }
    ]

    async function getVistaLocationRecord({ row, defaultTab = 5 }) {
        let params = {
            action: "CustomList", asArray: 0, limit: 10, start: 0, showAllImages: false, isFetchCoolerData: true,
            sortable: 'AvgStockPercentage', dir: 'ASC'
        };
        if (filterValues.uiClientIds?.toString()) {
            params.uiClientIds = filterValues.uiClientIds?.toString();
        }
        let assetPurityIdList = [];
        let { ProductInfo } = row;
        ProductInfo.forEach(({ AssetPurityId: assetPurityId, AssetPurityIds: assetPurityIds }) => {
            assetPurityIdList = assetPurityIdList.concat((assetPurityIds && assetPurityIds !== '' ? assetPurityIds.split(',') : [assetPurityId]).filter(imageId => !assetPurityIdList.includes(imageId)));
        });
        params = { ...params, assetOrLocationName: '', isForRank: true, LocationId: row.LocationId, assetPurityIds: assetPurityIdList.join() }
        const outletData = await request({ url: apis.assetLatestImagesNew, params, history, dispatch });

        setOutletCard({
            modal: true,
            data: {
                locationName: row.LocationName,
                code: row.Code,
                locationId: row.LocationId,
                clientId: row.ClientId
            },
            defaultTab: defaultTab,
            vistaData: outletData.records || []
        })
    }

    return userContext.isOrderInTransit ? <></> : <>
        <Card className={`mb-1 p-0  ${isMobileDevice.portrait ? "mt-4" : "mt-2"}`} >
            <CardContent className="m-0 p-0">
                <Tabs value={activeTab} onChange={handleChangeTab} centered variant="fullWidth">
                    {tabList.map(tab => {
                        return <Tab
                            key={tab.label}
                            className="pt-3"
                            icon={addBadge(tab.badge, <img src={require(`./../../assets/images/${tab.icon}`)} height={tab.iconSize} alt="" />)}
                            iconPosition={tab.iconPosition}
                            label={<span>{t(tab.label, tOpts)} {!isMobileDevice.mobile && <Tooltip title={t(tab.toolTipTitle, tOpts)} placement="right">
                                <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                            </Tooltip>}</span>}
                        />
                    })}
                </Tabs>
            </CardContent>
        </Card>

        <Box className="mt-2 section-to-print replenishment-card-container">
            <Box className={(isMobileDevice.mobile && !isMobileDevice.tablet) ? "pl-1 pr-1 pt-2 pb-2" : ""}>
                {getTab(activeTab)}
            </Box>
        </Box>

        <EditOrder />

        {outletCard.modal && <OutletCard outletCard={outletCard} setOutletCard={setOutletCard} defaultTab={outletCard.defaultTab} customClass={(isMobileDevice.mobile && !isMobileDevice.tablet) ? 'edit-order-mobile-overrides' : ""} />}

    </>
}

export default Replenishment;