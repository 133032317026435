
const env = process.env;
let url = (window.location.host.indexOf("localhost") !== -1) ? '' : env.REACT_APP_HOST;
let urlWithControllers = url + "/Controllers/"
const apis = {
    url: url,
    urlWithControllers,
    imgBaseUrl: env.REACT_APP_HOST,
    login: urlWithControllers + 'Login.ashx',
    assetLatestImages: urlWithControllers + 'AssetLatestImages.ashx',
    assetLatestImagesNew: urlWithControllers + 'AssetLatestImages2.ashx',
    smartDevice: urlWithControllers + 'SmartDevice.ashx',
    Asset: urlWithControllers + 'Asset.ashx',
    AssetType: urlWithControllers + 'AssetType.ashx',
    Product: urlWithControllers + 'Product.ashx',
    AssetPurityList: urlWithControllers + 'AssetPurity.ashx',
    OutOfStockReport: urlWithControllers + 'OutOfStockReport.ashx',
    AreaReports: urlWithControllers + 'AreaReports.ashx',
    Combo: urlWithControllers + 'Combo.ashx',
    productImageURL: url + '/products/',
    SkuOutOfStock: urlWithControllers + 'SkuOutOfStock.ashx',
    Telemetric: urlWithControllers + 'Telemetric.ashx',
    imageBaseDirectUrl: 'https://portal.coolrgroup.com/Controllers/CoolerImagePreview-Stitched-',
    UnileverReorder: urlWithControllers + 'UnileverReorder.ashx',
    ScheduledOrder: urlWithControllers + 'ScheduledOrder.ashx',
    AutoStock: urlWithControllers + 'AutoStock.ashx',
    ReorderDetail: urlWithControllers + 'ReorderDetail.ashx',
    ClientLogoBaseUrl: urlWithControllers + 'CoolerImagePreview.ashx',
    ScheduledOrderExport: `${urlWithControllers}ScheduledOrderExport.ashx`,
    assetLatestImages2: `${urlWithControllers}AssetLatestImages2.ashx`,
    ScheduledOrderMailHistory: `${urlWithControllers}ScheduledOrderMailHistory.ashx`,
    AcostaValidateReport: `${urlWithControllers}AcostaValidateReport.ashx`,
    LocationOrderHistory: `${urlWithControllers}LocationOrderHistory.ashx`,
    ImageProcessing: `${urlWithControllers}ImageProcessing.ashx`,
    DashboardHomeCharts: `${urlWithControllers}DashboardHomeCharts.ashx`,
    KPIReport: `${urlWithControllers}KPIReport.ashx`,
    ReplenishLocation: `${urlWithControllers}ReplenishLocation.ashx`,
    KPIConfig: `${urlWithControllers}KPIReport.ashx?action=otherAction&otherAction=GetClientKPIConfig`,
    Preference: `${urlWithControllers}GridPreferenceManager.ashx`,
    SwitchClient: `${urlWithControllers}SwitchClient.ashx`,
    Survey: urlWithControllers + 'Survey.ashx',
    ScheduledOrderStatusHistory: urlWithControllers + 'ScheduledOrderStatusHistory.ashx',
    SurveyType: urlWithControllers + "SurveyType.ashx",
    SurveyDetails: urlWithControllers + "SurveyDetails.ashx",
    Thumbnail: urlWithControllers + "Thumbnail.ashx",
    ClientUser: urlWithControllers + "ClientUser.ashx",
    Location: urlWithControllers + "Location.ashx",
    SystemUpdate: urlWithControllers + "PlaybookClient.ashx",
    SystemAlertConfig: urlWithControllers + "SystemAlertConfig.ashx",
    combo: urlWithControllers + "combo.ashx",
    downloadAttachment: urlWithControllers + 'DownloadAttachment.ashx',
    ChartPreference: urlWithControllers + "GridPreferenceManager.ashx",
    ProductOutOfStock: urlWithControllers + "ProductOutOfStock.ashx",
    ReorderDetailLite: urlWithControllers + 'ReorderDetailLite.ashx',

    // Reporting Tool API's
    KPIData: `${url}/internal-reporting/kpi`,
    KPIDetail: `${url}/internal-reporting/kpi/detail`,
    KPISummary: `${url}/internal-reporting/kpi/summary`,
    KPIFilter: `${url}/internal-reporting/kpi/lookupRelations`,
    MasterDataDash: `${url}/internal-reporting/dashboard/masterData`,
    ProductAvailabilityDash: `${url}/internal-reporting/dashboard/productAvailability`,
    InFieldHardware: `${url}/internal-reporting/dashboard/inFieldHardware`,
    AlertHistory: `${url}/internal-reporting/dashboard/operationsAlert`,
    SurveyDashboard: `${url}/internal-reporting/dashboard/surveyData`,
    ProductDistribution: `${url}/internal-reporting/dashboard/productDistribution`,
    PlanogramAsset: `${url}/internal-reporting/dashboard/planogramAsset`,
    OutletList: `${url}/internal-reporting/dashboard/outletList`,
    OutletCardView: `${url}/internal-reporting/dashboard/outletView`,
    CreateOutletGroupList: `${url}/internal-reporting/auditoutlet/createAndUpdate`,
    GetOutletGroupList: `${url}/internal-reporting/auditoutlet/list`,
    DeleteOutletGroupList: `${url}/internal-reporting/auditoutlet/delete`,
    DeleteOutlet: `${url}/internal-reporting/auditoutlet/deleteoutlet`,
    CreateUserGroupList: `${url}/internal-reporting/audituser/createAndUpdate`,
    GetUserGroupList: `${url}/internal-reporting/audituser/list`,
    DeleteUserGroupList: `${url}/internal-reporting/audituser/delete`,
    GetUserGroupMemberList: `${url}/internal-reporting/audituser/listUserGroupMember`,
    GetInGroupsData: `${url}/internal-reporting/audituser/listInGroups`,
    DeleteUser: `${url}/internal-reporting/audituser/delete/members`,
    LoadCombo: `${url}/internal-reporting/combos/loadCombo`,
    TrueDashboard: `${url}/internal-reporting/truedashboard/sensorReading`,
    DeleteOutletGroupListMember: `${url}/internal-reporting/auditoutlet/delete/members`,
    CreateAndUpdateSurvey: `${url}/internal-reporting/auditsurvey/createAndUpdate`,
    DeleteSurvey: `${url}/internal-reporting/auditsurvey/deleteSurvey`,
    ListSurveyConfig: `${url}/internal-reporting/auditsurvey/listAllSurveys`,
    getSurvey: `${url}/internal-reporting/auditsurvey/getSurvey`,
    PushSubscription: `${url}/internal-reporting/pushSubscription`,
    getSurveyStatus: `${url}/internal-reporting/auditsurvey/getSurveyStatus`,
    CategorizedSurveys: `${url}/internal-reporting/auditsurvey/listSurveyCategories`,
    getHasActiveSurvey: `${url}/internal-reporting/auditsurvey/getHasActiveSurvey`,
    surveyAnalysis: `${url}/internal-reporting/auditsurvey/surveyAnalysis`,
    getQuestionnaireInfo: `${url}/internal-reporting/auditsurvey/getQuestionnaireInfo`,
    createUpdateQuestionnaireInfo: `${url}/internal-reporting/auditsurvey/createUpdateQuestionnaireInfo`,
    docs: `${url}/internal-reporting/docs/`,
    getQuestionnaireLocationDetails: `${url}/internal-reporting/auditsurvey/getQuestionnaireLocationDetails`,
    getAsset: `${url}/internal-reporting/auditsurvey/getAsset`,
    questionnaire: `${url}/internal-reporting/api/SurveyType`,
    getChartsDropdown: `${url}/internal-reporting/charts/options`,
    getChartsData: `${url}/internal-reporting/charts/chartsdata`,
    MonthlyInvoice: `${url}/internal-reporting/api/MonthlyInvoice`,
    InFieldStatusCount: `${url}/internal-reporting/infieldstatuscount`,
    Pricing: `${urlWithControllers}Pricing.ashx`,
    ImportAsset: `${url}/internal-reporting/api/Asset/import`,
    ImportAssetType: `${url}/internal-reporting/api/AssetType/import`,
    ImportUser: `${url}/internal-reporting/api/MasterUser/import`,
    surveyAnalysisConstants: `${url}/internal-reporting/auditsurvey/surveyAnalysisConstants`,
    ImportOutlet: `${url}/internal-reporting/api/MasterOutlet/import`,
    GetMultiClientFilters: `${url}/internal-reporting/multiClientFilters`,
    ImportProduct: `${url}/internal-reporting/api/Product/import`,
    SurveyOperationalDashboard: `${url}/internal-reporting/auditsurvey/surveyOperationalDashboard`,
    SurveySubmissions: `${url}/internal-reporting/auditsurvey/surveySubmissions`,
    SurveyCoverage: `${url}/internal-reporting/auditsurvey/surveyCoverage`,
    SurveySubmissionsInTime: `${url}/internal-reporting/auditsurvey/surveySubmissionsInTime`,
    potentialSalesCount: `${url}/internal-reporting/charts/potentialsalescount`,
    potentialPendingCharts: `${url}/internal-reporting/charts/potentialPendingCharts`,
    ImportPlanogram: `${url}/internal-reporting/api/MasterPlanogram/import`,
    SurveyOperationsList: `${url}/internal-reporting/auditsurvey/surveyOperationsList`,
    AllSurveyOperationsList: `${url}/internal-reporting/auditsurvey/allSurveyOperationsList`,
    SurveyData: `${url}/internal-reporting/auditsurvey/getLocationInfo`,
    potentialPendingChartData: `${url}/internal-reporting/charts/potentialPendingCharts`,
    //External API
    TurkeyOrderValidate: "https://siparisapi.perabayi.net/telemetri/coolr/calculate",
    FilteredLookups: `${url}/internal-reporting/filteredLookups`,
    MyKPIConfigurator: `${url}/internal-reporting/MyKPIConfigurator`,
    CreateTicket: `${url}/internal-reporting/createTicket`,
    MissedOpportunities: `${url}/internal-reporting/MissedOpportunities`,
    SendErrorEmail: `${url}/internal-reporting/sendError`
}

export default apis;