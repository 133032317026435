import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { transport } from '../../httpUtil/httpRequest';
import utils from '../../utils';
import ChartToggleButtonGroup from '../ChartToggleButtonGroup';
import BarChart from './BarChart';
import { apis } from '../../httpUtil';
import { useTranslation } from 'react-i18next';

const addCurrencySign = ['Value'];
const chartKeys = { sales: { key: 'sales', value: 'Potential Sales' }, stock: { key: 'stock', value: 'Pending Time' } };

const DataBlock = ({ title, data = {}, currencySymbol, t, tOpts }) => (
  <Box className='datablock-container'>
    <Typography variant='h4' className='datablock-title'>{t(title, tOpts)}</Typography>
    {Object.keys(data).map((ele, index) => {
      const label = utils.chartCountLabelEnum[ele];
      let value = data[ele];
      if (addCurrencySign.includes(label)) {
        value = `${currencySymbol} ${value}`;
      }
      return (
        <React.Fragment key={index}>
          <Typography variant='h5' className='datablock-maintitle'>{t(label, tOpts)}</Typography>
          <Typography variant='h6' className='datablock-subtitle'>{value || 0}</Typography>
        </React.Fragment>
      )
    })}
  </Box>
);

const InboxOrderAnalysis = ({ valueField = 'FinalTotalOrder', caseField = 'FinalTotalCase', source, fetchData = false, tabsData, keyColumn, additionalWhere }) => {
  const [activePotentialSalesTab, setActivePotentialSalesTab] = useState(tabsData[0].value);
  const [activePendingTimeTab, setActivePendingTimeTab] = useState(tabsData[0].value);

  const [potentialSalesCounts, setPotentialSalesCounts] = useState({});
  const [pendingTimeCounts, setPendingTimeCounts] = useState({});

  const [potentialSalesChartData, setPotentialSalesChartData] = useState([]);
  const [pendingTimeChartData, setPendingTimeChartData] = useState([]);

  const dispatch = useDispatch();
  const userData = useSelector(state => state.appReducer.userData);
  const { CurrencySymbol } = userData?.tags ? userData.tags : 0;
  const t = utils.t
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const finalWhere = { ...additionalWhere, LastMOVAndMOQDateTime: { value: "", fieldName: "LastMOVAndMOQDateTime", operator: "IS NOT NULL" } };

  const getChartData = async (dataType) => {
    dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
    const aggregates = {};
    if (!dataType || dataType === "potentital") {
      aggregates[chartKeys.sales.key] = {
        measures: {
          [valueField]: {
            calc: "Sum"
          },
          [caseField]: {
            calc: "Sum"
          },
          LocationId: {
            calc: "Count"
          }
        },
        groupBy: [activePotentialSalesTab],
        source,
        where: finalWhere
      };
    }
    if (!dataType || dataType === "pendingTime") {
      aggregates[chartKeys.stock.key] = {
        measures: {
          OrdersWithDatesCount: {
            calc: "Sum"
          },
          [keyColumn]: {
            calc: "Count"
          },
          OrdersLessThan2DaysOldCount: {
            calc: "Sum"
          }
        },
        groupBy: [activePendingTimeTab],
        source,
        where: finalWhere
      };
      aggregates.count = {
        measures: {
          OrdersWithDatesCount: {
            calc: "Sum"
          },
          [keyColumn]: {
            calc: "Count"
          },
          OrdersLessThan2DaysOldCount: {
            calc: "Sum"
          }
        },
        groupBy: [activePendingTimeTab],
        source,
        where: finalWhere
      };
    }
    const response = await transport({
      url: apis.potentialPendingChartData,
      data: { aggregates },
      method: 'POST',
    });
    const { sales, stock, count } = response.data;
    if (sales) {
      const chartData = [];
      sales.records.map(record => {
        if (!record[activePotentialSalesTab]) {
          const nullGroupByIndex = chartData.findIndex(ele => ele.Categories === 'Not Defined');
          if (nullGroupByIndex > -1) {
            chartData[nullGroupByIndex].Data += record[valueField];
          } else {
            chartData.push({ Data: record[valueField], Categories: 'Not Defined' })
          }
        } else {
          chartData.push({ Data: record[valueField], Categories: record[activePotentialSalesTab] })
        }
      })
      const potentialSalesCount = { Orders: sales.agg.LocationId, Value: sales.agg[valueField], Cases: sales.agg[caseField] };
      setPotentialSalesCounts(potentialSalesCount);
      setPotentialSalesChartData(chartData);
    }

    if (stock) {
      const chartData = [];
      stock?.records.map(record => {
        if (!record[activePendingTimeTab]) {
          const nullGroupByIndex = chartData.findIndex(ele => ele.Categories === 'Not Defined');
          if (nullGroupByIndex > -1) {
            chartData[nullGroupByIndex].Data += record?.OrdersWithDatesCount;
          } else {
            chartData.push({ Data: record?.OrdersWithDatesCount, Categories: 'Not Defined' })
          }
        } else {
          chartData.push({ Data: record?.OrdersWithDatesCount, Categories: record[activePendingTimeTab] })
        }
      })
      setPendingTimeChartData(chartData);
    }

    if (count) {
      const pendingTimeCount = { AverageDays: Math.round((count?.agg?.OrdersWithDatesCount / count?.agg[keyColumn]) * 100), OrderCount: count?.agg?.OrdersLessThan2DaysOldCount };
      setPendingTimeCounts(pendingTimeCount);
    }

    dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
  }

  const handleTabChange = (e) => {
    const { target: { value } } = e;
    if (value !== activePotentialSalesTab) {
      setActivePotentialSalesTab(value);
    }
  }

  const handlePendingTimeTabChange = (e) => {
    const { target: { value } } = e;
    if (value !== activePendingTimeTab) {
      setActivePendingTimeTab(value);
    }
  }

  useEffect(() => {
    if (fetchData)
      getChartData();
  }, [activePendingTimeTab, activePotentialSalesTab, fetchData]);


  return (
    <Box className='order-analysis-container'>
      <Box className='order-analysis-datablock'>
        <DataBlock title='Potential Sales' data={potentialSalesCounts} currencySymbol={CurrencySymbol} t={t} tOpts={tOpts} />
        <Box style={{ width: '70%' }}>
          <ChartToggleButtonGroup tabs={tabsData} activeTab={activePotentialSalesTab} handleTabChange={handleTabChange} t={t} tOpts={tOpts} />
          <Box>
            <BarChart t={t} tOpts={tOpts} titleColor={utils.chartOptionEnum.potentialColor} seriesData={potentialSalesChartData} seriesName={utils.chartOptionEnum.potentialName} minBarHeight={15} CurrencySymbol={CurrencySymbol} />
          </Box>
        </Box>
      </Box>
      <Box className='order-analysis-datablock'>
        <DataBlock title='Pending Time' data={pendingTimeCounts} t={t} tOpts={tOpts} />
        <Box style={{ width: '70%' }}>
          <ChartToggleButtonGroup tabs={tabsData} activeTab={activePendingTimeTab} handleTabChange={handlePendingTimeTabChange} t={t} tOpts={tOpts} />
          <Box>
            <BarChart t={t} tOpts={tOpts} titleColor={utils.chartOptionEnum.pendingColor} seriesData={pendingTimeChartData} seriesName={utils.chartOptionEnum.pendingName} minBarHeight={3} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InboxOrderAnalysis;