import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import AddToHomeScreen from '@ideasio/add-to-homescreen-react';
import MuiTheme from '../theme';
import LeftSidebar from '../layout/LeftSidebar';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import utils from '../utils';
import enums from '../utils/constants'
import useMobile from '../utils/useMobile';
import { apis, request } from "../httpUtil";
import useGaTracker from '../gaTracker'
import Loader from '../components/Dialog/Loader';
import VistaImagePopup from '../components/VistaLocationCardList/VistaImagePopup';
import { HelmetProvider } from 'react-helmet-async';
import * as locales from "@material-ui/core/locale";
import useFreshChat from '../utils/freshChat'
import actions from '../redux/actions';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import ScrollUpButton from "react-scroll-up-button";
import outletGroupModel from '../pages/outlet-group';
import outletactions from '../pages/outletactions'
import userGroupModel from '../pages/user-group';
import surveyModel from '../pages/SurveyConfig';
import assetList from "../pages/assetList";
import userLoginModel from '../pages/user-login';
import QuestionnaireConfig from '../pages/QuestionnaireConfig';
import QuestionnaireEditor from '../components/Survey/QuestionnaireEditorInHouse';
import FrequencySummary from '../components/Survey/FrequencySummary';
import surveyReportsModel from '../pages/survey-report';
import SurveyAnalysis from '../components/SurveyAnalysis/AnalysisDetails';
import acostaReportListModel from '../pages/acosta-report-list';
import Settings from '../components/DataAndSetting/SystemSettings';
import MasterDataDashboard from '../pages/UnileverReport/masterData';
import { useStateContext } from "@durlabh/dframework-ui";
import { store } from '../../src/redux/store';
import Invoicing from '../pages/Invoicing';
import VistazReportList from '../pages/vistaz-report';
import InFieldStatus from '../pages/InFieldStatus';
import surveyInboxModel from '../pages/SurveyInbox';
import SurveyOperationalDashboard from '../components/Survey/SurveyOperationalDashboard';
import tablePreferenceEnums from '../utils/tablePreferenceEnums';
import DeviceTracking from '../pages/DeviceTracking';
import DataAsset from '../pages/Data';
import ImportLogModel from '../pages/import-log';
import ForgotPassword from '../pages/Login/ForgotPasswordComponent';
import MyKPIConfigurator from '../components/MyKPIConfigurator/index';
import MissedOpportunities from '../components/MissedOpportunities/index';
import Login from '../pages/Login';
import Home from '../pages/Home/index';
import AcostaReport from '../pages/AcostaReport';
import AssetReport from '../pages/assetReport';
import BusinessKPIHome from '../pages/BusinessPerformance';
import BusinessKPI from '../pages/BusinessPerformance/businessKPI';
import OperationsHome from '../pages/Operations/';
import Operations from '../pages/Operations/operations';
import ReplenishmentHome from '../pages/Replenishment';
import OutletCards from '../pages/Outlets';
import ChooseSSO from '../pages/Login/ChooseSSO';
import Replenishment from '../pages/Replenishment/Replenishment';
import SurveyHome from '../pages/Survey';
import SurveyReport from '../components/SurveyDashboardComponent/surveyReport';
import SurveyAnswer from '../pages/RenderSurveyAnswer';
import SurveyDashboard from '../components/SurveyDashboardComponent/surveyDashboard';
import TrueGatewayHome from '../pages/TrueGateway';
import TrueDashboard from '../pages/TrueGateway/trueGateway';
import Installations from '../pages/Installations';
import Docs from '../pages/Docs';

const { childRoutes } = enums;
const t = utils.t


let loginDataCount = 0;
const salesRep = 2;

const SuspenseLoading = ({ t, tOpts }) => <>{t("Loading...", tOpts)}</>

const processReLogin = (loginData, history, dispatch) => {
    if (Object.keys(loginData).length === 0 && !loginDataCount) {
        utils.reLogin(history); //re-login on redirect
        loginDataCount++;
        return false;
    }
}
const loggedIn = (loginData) => {
    let message = loginData && loginData.message ? loginData.message : "";
    return (message === "Logged in");
};

const hasPermission = ({ loginData, model, module }) => {
    if (!loginData.modules) return false;
    module = typeof module === 'string' ? module : model?.module;
    return loginData.modules[module]?.Module === true;
}

const MasterPageAuthenticatedRoute = React.memo(({ component: DynamicComponent, model, module, ...rest }) => {
    const loginData = useSelector(state => state.appReducer.userData, shallowEqual) || {},
        dispatch = useDispatch(),
        history = useHistory();
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };

    return <Route {...rest} render={props => {
        processReLogin(loginData, history, dispatch);
        if (loggedIn(loginData)) {
            if (!hasPermission({ loginData, model, module }) && props.match.path !== "/") {
                swal({ title: "You do not have permission to view content on this page", icon: "error" });
                return <div></div>
            }
            // Check if current route is the one with params (a child route)
            if (props?.match?.params && Object.keys(props.match.params).length) {
                let backRoute = props.match.path;
                // Extract the parent route by removing the param and extra slash
                backRoute = backRoute.split(':')[0].slice(0, -1);
                // Apply back button to directly go to parent
                dispatch({ type: actions.SET_PAGE_BACK_BUTTON, pageBackButton: { status: true, backRoute: backRoute } });
            }
            return <DynamicComponent {...props} />
        } else {
            if (Object.keys(loginData).length === 0 && !loginDataCount) {
                utils.reLogin(history); //re-login on redirect
                return false;
            }
            else if (loginDataCount > 1) {
                return <Redirect to="/" />
            }
        }
    }} />
});

function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

const Routes = () => {
    const childPages = childRoutes.Survey;
    const { t: translate, i18n } = useTranslation()
    let { mobile, tablet } = useMobile();
    const tOpts = { t: translate, i18n };
    const loginData = useSelector(state => state.appReducer.userData, shallowEqual) || {};
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const sidebarToggleMobile = useSelector(state => state.appReducer.sidebarToggleMobile);
    const routePath = Object.keys(loginData).length ? utils.getAssignedRoutes(loginData) : []
    const routeDirection = [];
    const { ScheduledOrderId = 0, RoleId } = loginData && loginData.tags ? loginData.tags : 0;
    const { dispatchData } = useStateContext();
    let reduxStore = store?.getState()?.appReducer;
    let userData = reduxStore.userData ? reduxStore.userData.tags : {};
    routeDirection.push("");

    const regWorker = async () => {
        const reg = await navigator.serviceWorker.ready
        if (reg && Number(RoleId) === salesRep) {
            const subscribe = await reg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(utils.push_notification_pubKey)
            })
            if (subscribe) {
                const additionalParams = {}
                const payload = {
                    subscription: JSON.stringify(subscribe)
                }
                await request({ url: apis.PushSubscription, params: payload, dispatch, history, jsonPayload: true, additionalParams });
            }
        }
    }
    React.useEffect(() => {
        dispatchData({ type: 'UPDATE_DATE_TIME', payload: userData?.DateTimeFormat });
        dispatchData({ type: 'USER_DATA', payload: userData });
        dispatchData({ type: 'SET_GRID_SETTINGS', payload: { permissions: { edit: true, add: true, export: true, delete: true, Url: `${apis.url}/internal-reporting/api/`, withControllersUrl: apis.urlWithControllers, preferenceApi: apis.Preference, routesWithNoChildRoute: ['/Installations', '/UserLogin', '/MasterData', '/InFieldStatus', "/Outlet"], tablePreferenceEnums: tablePreferenceEnums } } })
    }, [userData?.DateTimeFormat]);
    React.useEffect(() => {
        if (loginData && loginData.tags) {
            const requestNotificationPermission = async () => {
                const permission = await window.Notification.requestPermission()
                if (permission === 'granted') {
                    regWorker();
                }
            }
            requestNotificationPermission();
            const clientSelected = Number(loginData.tags.ClientId);
            if (!clientSelected) {
                swal({
                    title: "Info",
                    text: "No Client is selected, Some functionalities might not work properly! Please select a client",
                    icon: "info",
                })
            }
        }
        window.setDynamicUserId(loginData.userId);
    }, [loginData])

    React.useEffect(() => {
        const isLoginDataExist = Boolean(Object.keys(loginData)?.length !== 0);
        if ((location.pathname.includes("/assetReport/") && isLoginDataExist) || location.pathname.includes("/docs") || location.pathname.includes("/forgot-password"))
            return;
        processReLogin(loginData, history, dispatch);
    }, [loginDataCount, loginData]);



    for (let path of routePath) {
        if (loginData && loginData.modules && loginData.modules[path.slice(1)]?.Module) {
            routeDirection.push(path)
        }
    }

    const pageVariants = {
        initial: { opacity: 0 },
        in: { opacity: 1 },
        out: { opacity: 0 }
    };


    const pageTransition = { type: 'tween', ease: 'anticipate', duration: 0.4 };
    let currentLocale = useSelector(state => state.appReducer.locale) || "";
    currentLocale = currentLocale.replace("-", "");
    useGaTracker(); // Initialize the GA Tracker
    // Initialize the Fresh chat
    useFreshChat({ sidebarToggleMobile, mobile, loginData });

    useEffect(() => {
        const removeFilter = history.listen(() => {
            dispatch({ type: actions.SET_FILTER_VALUES, filterValues: {} });
        });

        return () => {
            removeFilter();
        };
    }, [history])


    return (
        <HelmetProvider>
            <ThemeProvider theme={createTheme(MuiTheme, locales[currentLocale])}>
                <AnimatePresence>
                    <Suspense fallback={<SuspenseLoading t={t} tOpts={tOpts} />}>
                        <Switch>
                            <Route path={['/docs']}>
                                <Switch location={location} key={location.pathname}>
                                    <motion.div
                                        initial="initial"
                                        animate="in"
                                        exit="out"
                                        variants={pageVariants}
                                        transition={pageTransition}>
                                        <Route path="/docs" component={Docs} />
                                    </motion.div>
                                </Switch>
                            </Route>
                            <MasterPageAuthenticatedRoute path="/" exact component={Login} />
                            <Route path={['/login']}>
                                <Switch location={location} key={location.pathname}>
                                    <motion.div
                                        initial="initial"
                                        animate="in"
                                        exit="out"
                                        variants={pageVariants}
                                        transition={pageTransition}>
                                        <Route path="/login" component={Login} />
                                    </motion.div>
                                </Switch>
                            </Route>
                            <Route path={['/forgot-password']}>
                                <Switch location={location} key={location.pathname}>
                                    <motion.div
                                        initial="initial"
                                        animate="in"
                                        exit="out"
                                        variants={pageVariants}
                                        transition={pageTransition}>
                                        <Route path="/forgot-password" component={ForgotPassword} />
                                    </motion.div>
                                </Switch>
                            </Route>
                            <Route path={['/choosesso']}>
                                <Switch location={location} key={location.pathname}>
                                    <motion.div
                                        initial="initial"
                                        animate="in"
                                        exit="out"
                                        variants={pageVariants}
                                        transition={pageTransition}>
                                        <Route path="/choosesso" component={ChooseSSO} />
                                    </motion.div>
                                </Switch>
                            </Route>
                            <Route exact path={["/assetReport/:GUID", "/assetReport/:GUID/:fromNotification"]} component={AssetReport} />
                            <Route path={routeDirection}>
                                <LeftSidebar>
                                    <Suspense fallback={<SuspenseLoading t={t} tOpts={tOpts} />}>
                                        <Switch location={location} key={location.pathname}>
                                            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition} style={{ height: "100%", display: 'flex', flexDirection: 'column' }}>
                                                {/* New Routes */}
                                                <MasterPageAuthenticatedRoute exact path="/Home" module="Home" component={Home} />
                                                <MasterPageAuthenticatedRoute exact path="/Operations" module="Operations" component={OperationsHome} />
                                                <MasterPageAuthenticatedRoute exact path="/Operations/:page" module="Operations" component={Operations} />
                                                <MasterPageAuthenticatedRoute exact path="/Outlet" module="PlaybookOutlet" component={OutletCards} />
                                                <MasterPageAuthenticatedRoute exact path="/BusinessPerformance/" module="BusinessPerformance" component={BusinessKPIHome} />
                                                <MasterPageAuthenticatedRoute exact path="/BusinessPerformance/:page" module="BusinessPerformance" component={BusinessKPI} />
                                                <MasterPageAuthenticatedRoute exact path="/Replenishment" module="Replenishment" component={ReplenishmentHome} />
                                                <MasterPageAuthenticatedRoute exact path={["/Replenishment/:page", "/Replenishment/:page/:locationId/:statusId"]} module="Replenishment" component={Replenishment} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey" module="Survey" component={SurveyHome} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/Survey/FrequencySummary/:id" module="Survey" component={FrequencySummary} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/OutletGroup" component={outletGroupModel.Grid} model={outletGroupModel} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/OutletGroup/:id" component={outletGroupModel.Form} model={outletGroupModel} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/UserGroup" component={userGroupModel.Grid} model={userGroupModel} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/UserGroup/:id" component={userGroupModel.Form} model={userGroupModel} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/Survey" component={surveyModel.Grid} model={surveyModel} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/Survey/:id" component={surveyModel.Form} model={surveyModel} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/Questionnaire" component={QuestionnaireConfig.Grid} model={QuestionnaireConfig} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/Questionnaire/:id" module="Questionnaire" component={QuestionnaireEditor} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/Dashboard" module="SurveyDashboard" component={SurveyDashboard} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/Report" module="SurveyReport" component={SurveyReport} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/Operations" module="SurveyOperations" component={SurveyOperationalDashboard} />
                                                {/* NEW ROUTE FOR WIP CARD  */}
                                                <MasterPageAuthenticatedRoute exact path="/Survey/SurveyInbox" component={surveyInboxModel.Grid} model={surveyInboxModel} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/Analysis" component={surveyReportsModel.Grid} model={surveyReportsModel} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/Analysis/:id" module="SurveyAnalysis" component={SurveyAnalysis} />
                                                <MasterPageAuthenticatedRoute exact path="/SurveyInbox/:LocationId" module="SurveyReport" component={SurveyAnswer} />
                                                <MasterPageAuthenticatedRoute exact path="/SurveyReport/:LocationId" module="SurveyReport" component={SurveyAnswer} />
                                                <MasterPageAuthenticatedRoute exact path="/AcostaReports" module="AcostaReports" component={AcostaReport} model={acostaReportListModel} />
                                                <MasterPageAuthenticatedRoute exact path="/TrueGateway" module="TrueGateway" component={TrueDashboard} />
                                                <MasterPageAuthenticatedRoute exact path="/Installations" component={assetList.Grid} model={assetList} />
                                                <MasterPageAuthenticatedRoute exact path="/UserLogin" component={userLoginModel.Grid} model={userLoginModel} />
                                                <MasterPageAuthenticatedRoute exact path="/MasterData" module="MasterData" component={MasterDataDashboard} />
                                                <MasterPageAuthenticatedRoute exact path="/InFieldStatus" component={InFieldStatus} module="infieldStatus" />
                                                <MasterPageAuthenticatedRoute exact path="/Settings" module="Settings" component={Settings} />
                                                <MasterPageAuthenticatedRoute exact path="/Invoicing" component={Invoicing} module="Invoicing" />
                                                <MasterPageAuthenticatedRoute exact path="/DeviceTracking" component={DeviceTracking} module="DeviceTracking" />
                                                <MasterPageAuthenticatedRoute exact path="/Data" component={DataAsset} module="DataPage" />
                                                <MasterPageAuthenticatedRoute exact path="/ImportLogs" component={ImportLogModel.Grid} model={ImportLogModel} />
                                                <MasterPageAuthenticatedRoute exact path="/Reporting" component={VistazReportList.Grid} model={VistazReportList} />
                                                <MasterPageAuthenticatedRoute exact path="/OutletActions/:id" module="OutletActions" component={outletactions.Form} model={outletactions} />
                                                <MasterPageAuthenticatedRoute exact path="/OutletActions" module="OutletActions" component={outletactions.Grid} model={outletactions} />
                                                <MasterPageAuthenticatedRoute exact path="/MyKPIConfigurator" module="MyKPIConfigurator" component={MyKPIConfigurator} />
                                                <MasterPageAuthenticatedRoute exact path="/MissedOpportunities" module="MissedOpportunities" component={MissedOpportunities} />
                                            </motion.div>
                                        </Switch>
                                    </Suspense>
                                </LeftSidebar>
                                <Loader />
                                <VistaImagePopup />
                                {!Number(ScheduledOrderId) > 0 && <ScrollUpButton />}
                                {!Number(ScheduledOrderId) > 0 && <AddToHomeScreen
                                    appId='com.coolr.dashboard'
                                    startAutomatically={true}
                                    startDelay={0}
                                    lifespan={30}
                                    skipFirstVisit={false}
                                    displayPace={0}
                                    customCriteria={mobile || tablet}
                                    customPromptElements={{
                                        container: 'athContainer'
                                    }}
                                    customPromptPlatformDependencies={{
                                        chromium: {
                                            images: [
                                                {
                                                    src: 'images/guidance/chromium.png',
                                                    alt: 'Guide to install application on home screen.'
                                                }
                                            ]
                                        },
                                        edge: {
                                            images: [
                                                {
                                                    src: 'images/guidance/chromium.png',
                                                    alt: 'Guide to install application on home screen.'
                                                }
                                            ]
                                        },
                                        iphone: {
                                            images: [
                                                {
                                                    src: 'images/guidance/iphone.png',
                                                    alt: 'Guide to install application on home screen.'
                                                }
                                            ]
                                        },
                                        ipad: {
                                            images: [
                                                {
                                                    src: 'images/guidance/ipad.png',
                                                    alt: 'Guide to install application on home screen.'
                                                }
                                            ]
                                        },
                                        firefox: {
                                            images: [
                                                {
                                                    src: 'images/guidance/firefox.png',
                                                    alt: 'Guide to install application on home screen.'
                                                }
                                            ]
                                        },
                                        samsung: {
                                            images: [
                                                {
                                                    src: 'images/guidance/firefox.png',
                                                    alt: 'Guide to install application on home screen.'
                                                }
                                            ]
                                        },
                                        opera: {
                                            images: [
                                                {
                                                    src: 'images/guidance/opera.png',
                                                    alt: 'Guide to install application on home screen.'
                                                }
                                            ]
                                        }
                                    }}
                                />}
                            </Route>
                        </Switch>
                    </Suspense>
                </AnimatePresence>
            </ThemeProvider>
        </HelmetProvider>
    );
};

export default Routes;