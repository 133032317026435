
import React, { useState, useEffect } from 'react';
import { Card, Grid } from '@material-ui/core';
import { apis, request } from '../../httpUtil';
import { useHistory } from 'react-router-dom';
import { Info, ExpandLess } from '@material-ui/icons';
import addIcon from '../../assets/images/add.svg';
import utils from '../../utils';
import dateUtil from '../../utils/date';
import dayjs from "dayjs";
import useMobile from '../../utils/useMobile';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { DataGridPremium, useGridApiRef, GridColumnMenu } from '@mui/x-data-grid-premium';

const t = utils.t;

const capitalize = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

const GridBase = (props) => {
    const { columns,
        data,
        customClass = '',
        autoHeight = true,
        otherOptions = {},
        gridRef = null,
        initialState,
    } = props;

    const [hasColAndRows, setHasColAndRows] = useState(false);
    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    const gridRefLocal = useGridApiRef();
    const apiRef = gridRef ? gridRef : gridRefLocal;
    const locale = useSelector(state => state.appReducer.locale);

    function CustomColumnMenu(props) {
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    // Hide `columnMenuColumnsItem`
                    columnMenuColumnsItem: null,
                    columnMenuGroupingItem: null
                }}
            />
        );
    }

    useEffect(() => {
        if (!columns) {
            return;
        }
        if (columns.length > 0) {
            const getColumns = columns.map((column) => {
                const { id, label, minWidth, width, colSpan, filterable, sortable, flex, type, headerAlign, align, valueOptions, hide, headerFilter, format, formatJSX, sortComparator, valueGetter, filterOperators, renderCell, columnMenu, filter, sort, editable, renderEditCell, aggregable } = column;

                const colObject = {
                    field: id,
                    headerName: t(capitalize(label), tOpts),
                    minWidth: minWidth,
                    width: width || null,
                    colSpan: colSpan || null,
                    filterable: filterable ?? true,
                    sortable: sortable ?? true,
                    flex: flex === undefined ? 1 : flex,
                    type: type,
                    headerAlign: headerAlign || null,
                    align: align || null,
                    valueOptions: valueOptions,
                    hide: hide,
                    headerFilter: headerFilter,
                }
                if (hide) {
                    colObject.hide = hide;
                }
                if (format) {
                    colObject.valueFormatter = format;
                }
                if (formatJSX) {
                    colObject.renderCell = formatJSX;
                }
                if (sortComparator) {
                    colObject.sortComparator = sortComparator;
                }
                if (valueGetter) {
                    colObject.valueGetter = valueGetter;
                }
                if (filterOperators) {
                    colObject.filterOperators = filterOperators
                }
                if (renderCell) {
                    colObject.renderCell = renderCell
                }
                if (columnMenu) {
                    colObject.filterOperators = columnMenu
                }
                if (filter) {
                    colObject.filterable = filter
                }
                if (sort) {
                    colObject.sortable = sort
                }
                if (editable) {
                    colObject.editable = editable
                }
                if (renderEditCell) {
                    colObject.renderEditCell = renderEditCell
                }
                if (headerFilter) {
                    colObject.headerFilter = headerFilter;
                }
                if (aggregable !== undefined) {
                    colObject.aggregable = aggregable;
                }
                return colObject;
            });
            setGridColumns(getColumns)
            setHasColAndRows(true);
        }

    }, [columns, locale])

    useEffect(() => {
        if (!data) {
            return;
        }
        if (data && Array.isArray(data) && data.length) {
            const getRows = data.map(row => {
                let rowId;
                const objKeys = Object.keys(row)
                objKeys.map((obj, index) => {
                    row[obj] = Object.values(row)[index]
                })
                if (row.id) {
                    return row;
                } else {
                    rowId = utils.generateUUID();
                    return { id: rowId, ...row }
                }
            })
            setGridRows(getRows);
        } else {
            setGridRows([]);
        }

    }, [data]);

    return (
        <>
            {hasColAndRows ?
                <DataGridPremium
                    className={`data-grid-base ${customClass}`}
                    columns={gridColumns}
                    rows={gridRows}
                    autoHeight={autoHeight}
                    initialState={initialState}
                    {...otherOptions}
                    hideFooterRowCount={true}
                    apiRef={apiRef}
                    defaultGroupingExpansionDepth={-1}
                    slots={{ columnMenu: CustomColumnMenu }}
                    autoHideColumn={false}
                />
                : <h4 className='text-center pt-5'>{t('Loading...', tOpts)}</h4>}
        </>
    );
};



function ExpandedRow(props) {
    const { data = [], onAddItem, isReorder = false, updateLocation, locationId, t: translate, i18n, onDelete, row, onClickItem, closeExpandedRows, currency } = props;
    function formatCurrency(number, currency) {
        const formattedNumber = number.toFixed(2);
        if (!isNaN(formattedNumber)) {
            return currency?.replace('{0}', formattedNumber);
        } else {
            return ''
        }

    }

    const expandedColumns = [
        {
            id: 'ProductId', label: 'Image', minWidth: 65, aggregable: false, filter: false, sort: false, valueGetter: (value) => value.row, type: "string", formatJSX: (params) => {
                return (
                    <div className="d-flex d-flex-justify-center">
                        {params.row.ProductId ? <img alt='...' src={apis.imgBaseUrl + "/controllers/thumbnail.ashx?imageType=Thumbnail&ImagePath=" + params.row.ProductId + ".png&maxWidth=30"} className="expanded-row-card-product py-2" /> : ''}
                    </div>
                )
            }
        },
        { id: 'UnitsNeeded', label: 'Qty', minWidth: 40, filter: false, sort: false, valueGetter: (value) => Number(value.row.UnitsNeeded), type: "number" },
        {
            id: 'Amount', label: 'Value', minWidth: 40, filter: false, sort: false, type: "number", valueGetter: (value) => Number(value.row.Amount), format: (item) => {
                return formatCurrency(item.value, currency);
            }
        },
    ];
    const history = useHistory();
    const dispatch = useDispatch();
    const isMobile = useMobile();
    const [productId, setProductId] = useState(0);
    const [caseSize, setCaseSize] = useState(0);
    const [assetLocation, setAssetLocation] = useState([])
    const tOpts = { t: translate, i18n };
    const gridRef = useGridApiRef();
    const onEdit = (e, item) => {
        setProductId(item.ProductId);
        setCaseSize(item.CaseSize);
    }
    const onQuantityChange = (e, item) => {
        const { value } = e.target;
        //Update quantity
        if (Number(value) > -1) {
            item.UnitsNeeded = Number(value);
            updateLocation(locationId, item);
        }
        setProductId(0)
    }

    const uniqueRows = [];

    data.forEach(row => {
        const { ProductName, CaseSize } = row;
        const existingRow = uniqueRows.find(r => r.ProductName === ProductName && r.CaseSize === CaseSize);

        if (existingRow) {
            existingRow.UnitsNeeded += row.UnitsNeeded;
            existingRow.Amount += row.Amount;
        } else {
            uniqueRows.push({ ...row });
        }
    });

    const sortedData = uniqueRows.sort(sortData).map((data, index) => {
        return {
            ...data,
            hierarchy: [data.ProductCategoryName ? data.ProductCategoryName : 'No Category', `${data.ProductName} (${data.CaseSize} pack)`],
            rowIndex: index
        }
    });

    useEffect(() => {

        setAssetLocation([]);
        getLastImage();

    }, [row])

    useEffect(() => {
        if (productId) {
            document.getElementsByName("productQty")[0] && document.getElementsByName("productQty")[0].focus();
        }
    }, [productId])

    async function getLastImage() {

            let params = {
                action: "CustomList", asArray: 0, limit: 10, start: 0, showAllImages: false, isFetchCoolerData: true,
                sort: 'AvgStockPercentage', dir: 'ASC'
            };
            let assetPurityIdList = [];
            let { ProductInfo } = row;
            ProductInfo.forEach(({ AssetPurityId: assetPurityId, AssetPurityIds: assetPurityIds }) => {
                assetPurityIdList = assetPurityIdList.concat((assetPurityIds && assetPurityIds !== '' ? assetPurityIds.split(',') : [assetPurityId]).filter(imageId => !assetPurityIdList.includes(imageId)));
            });
            params = { ...params, assetOrLocationName: '', isForRank: true, LocationId: row.LocationId, assetPurityIds: assetPurityIdList.join() }
            const response = await request({ url: apis.assetLatestImagesNew, params, history, dispatch });
            setAssetLocation(response?.records || [])
      
    }

    function sortData(objF, ObjS) {
        const first = Number(objF.UnitsNeeded) * Number(objF.UnitPrice), second = Number(ObjS.UnitsNeeded) * Number(ObjS.UnitPrice);
        if (first < second) {
            return 1;
        }
        if (first > second) {
            return -1;
        }
        return 0;
    }

    const getRowClassName = (params) => {
        return `custom-row-style-${params.row.rowIndex}`;
    }

    const getTreeDataPath = (row) => row.hierarchy;
    const getRowId = (row) => row.hierarchy.join('/');

    const generateInlineStyles = () => {
        let styles = '';
        sortedData.map((value, index) => {
            styles += `
            .custom-row-style-${value.rowIndex} {
              background-color: ${value.bgColor ? value.bgColor : ''};
              padding: 5px 0px;
              min-Height: 60px !important
              white-space: normal !important;
              word-wrap: break-word !important;
            }
          `;
        });
        return styles;
    };

    const inlineStyles = generateInlineStyles();

    return (
        <div>
            <style>{inlineStyles}</style>
            <Grid className='' container spacing={1}>

                {isMobile.mobile && !isMobile.tablet &&
                    <Grid item xs={12} sm={12} md={12} lg={12} id={`detail-panel-icons-div-${row.LocationId}`}>
                        <div className='d-flex justify-space-between align-center w-100'>
                            <div onClick={() => onClickItem({ row, defaultTab: 0 })}><Info color="primary" /></div>
                            <div className='expandLessArrow' onClick={closeExpandedRows}><ExpandLess /></div>
                        </div>
                    </Grid>
                }
                <Grid container >


                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        {assetLocation.length > 0 && assetLocation.map((asset, index) => {
                            let child = null;
                            if (asset.IsParentAsset && asset.Children && asset.Children[0]) {
                                child = asset.Children[0];
                            }
                            return (
                                <div className='expand-asset-container'>
                                    <p className=" mb-1 expand-asset-title">{`${asset.AssetSerialNumber} - ${dayjs(dateUtil.dateParse(asset.PurityDateTime)).format(utils.systemDateTimeFormat(true, true) + ' HH:mm')}`}</p>
                                    <div className='location-expand-image-container' onClick={() => dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: `${asset.StitchedImageURL}` } })}>
                                        <img alt='...' src={`${asset.StitchedImageURL}&maxWidth=350`} className='location-expand-image' />
                                    </div>
                                    {child && <>
                                        <p className=" mb-1 expand-asset-title">{`${child.AssetSerialNumber} - ${dayjs(dateUtil.dateParse(child.PurityDateTime)).format(utils.systemDateTimeFormat(true, true) + ' HH:mm')}`}</p>
                                        <div className='location-expand-image-container' onClick={() => dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: `${child.StitchedImageURL}` } })}>
                                            <img alt='...' src={`${child.StitchedImageURL}&maxWidth=350`} className='location-expand-image' />
                                        </div>
                                    </>}
                                </div>
                            )
                        })}
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <GridBase
                            customClass={'mt-2 py-2 data-grid-font-12 bordered-data-grid'}
                            columns={expandedColumns}
                            data={sortedData}
                            showTitleColumn={true}
                            gridRef={gridRef}
                            pagination={false}
                            disableColumnMenu={true}
                            hideFooter={true}
                            disableColumnSelector={true}
                            otherOptions={{
                                initialState: {
                                    aggregation: {
                                        model: {
                                            UnitsNeeded: "sum",
                                            Amount: "sum"
                                        }
                                    }
                                },
                                getRowClassName: getRowClassName,
                                getTreeDataPath: getTreeDataPath,
                                getRowId: getRowId,
                                treeData: true,
                                groupingColDef: { headerName: 'Product Name', width: 140, },
                            }}
                        />
                    </Grid>

                </Grid>
                {
                    onAddItem && <Grid item xs={2} sm={2} md={2} lg={2} >
                        <Card className="expand-item-center " style={{ minHeight: '60px' }}>
                            <img src={addIcon} alt='Add' height="18" />
                            <span className="add-item" onClick={onAddItem} >{t("ADD ITEM", tOpts)}</span>
                        </Card>
                    </Grid>
                }

            </Grid>
        </div>
    )
}

export default withTranslation()(ExpandedRow);