import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { AutoSizer, CellMeasurer, CellMeasurerCache, Grid, InfiniteLoader, WindowScroller } from "react-virtualized";
import { Card, CardContent, Typography, CardHeader, CardActions, Switch, FormControlLabel } from "@material-ui/core";
import { useDebounce } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MapIcon from '@mui/icons-material/Map';
import WindowIcon from '@mui/icons-material/Window';
import SquareIcon from '@mui/icons-material/Square';
import utils from "../../utils"
import actions from '../../redux/actions';
import { request, apis } from "../../httpUtil";
import OutletMap from './outletMap';
import dayjs from 'dayjs';
import FilterPanel from '../filterPanel';
import VistaSearch from '../VistaLocationCardList/VistaSearch';
import OutletCard from './outletCard';
import useMobile from '../../utils/useMobile';
import constants from '../../utils/constants';

const MIN_BATCH_SIZE = 30;
const LARGE_CARD_VIEW_SIZE = 680;
let grid, onRowsRenderedG, initialLoad = false;
const { t, numberWithCommas } = utils;
const viewOptions = {
  ThreeCards: { value: 3, text: 'Large' },
  FiveCards: { value: 5, text: 'Small' },
  MapView: { value: 1, text: 'Map' }
}

const dateFilterMaxDate = new Date(new Date().setDate(new Date().getDate() - 1));

function Outlets() {
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMobile();
  const userData = useSelector(state => state.appReducer.userData);
  const vistaSearch = useSelector(state => state.appReducer.vistaSearch);
  const comboData = useSelector(state => state.appReducer.comboData);
  const [searchDebounce] = useDebounce(vistaSearch, 1000)
  const [columnCount, setColumnCount] = useState((!isMobile.tablet && isMobile.mobile) ? 2 : 5);
  const [rowCount, setRowCount] = useState(1);
  const [toggleMaps, setToggleMaps] = useState(false);
  const [outletCard, setOutletCard] = useState({ modal: false, data: "" });
  const [outletList, setOutletList] = useState({ outlets: [], totalCount: 0 });
  const [mapList, setMapList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activeView, setActiveView] = useState(viewOptions.FiveCards.value);
  const switchRef = useRef(false)
  const { CustomDateFormat: DateFormat } = userData && userData.tags ? userData.tags : 0;
  const isLoading = useRef(false), lastIndexRef = useRef(''), widthRef = useRef(null);
  const filterValues = useSelector(state => state.appReducer.filterValues);
  const stateCombo = useSelector(state => state.appReducer.stateCombo);
  const selectedClient = useSelector(state => state.appReducer.selectClient);
  
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: activeView === viewOptions.ThreeCards.value ? 640 : 320
  });

  const formattedSalesPersonCombo = (comboData?.SalesPerson ?? []).map(obj => ({
    ...obj,
    DisplayValue: obj.DisplayValue.charAt(0).toUpperCase() + obj.DisplayValue.slice(1).toLowerCase()
  }));

  const handleChange = (event, newAlignment) => {
    if (newAlignment) {
      const isMapView = newAlignment === viewOptions.MapView.value;
      setToggleMaps(isMapView);
      switchRef.current = isMapView;
      if (newAlignment === viewOptions.ThreeCards.value || newAlignment === viewOptions.FiveCards.value) {
        setColumnCount(newAlignment);
      }
      setActiveView(newAlignment);
    }
  };

  const headerButtonsMiddle = () => {
    const totalOutletCount = numberWithCommas(totalCount);
    return (<>
      <p className='m-1 sub-header-button outlet-count'>{`${(isMobile.mobile && !isMobile.tablet) ? "" : t('Outlets: ', tOpts)}${totalOutletCount}`}</p>
    </>)
  }

  const handleViewChange = (e) => {
    switchRef.current = e.target.checked;
    setActiveView(e.target.checked ? viewOptions.MapView.value : viewOptions.FiveCards.value);
    setToggleMaps(prev => !prev);
  };

  const headerButtonsRight = () => {
    return (
      <>
        {isMobile.mobile ?
          <>
            <FormControlLabel
              control={<Switch className="hide-switch" />}
              labelPlacement="start"
              label={<Typography className="outlet-switch-label">{t('List', tOpts)}</Typography>}
            />
            <Switch
              ref={switchRef}
              className="space-outlet-switch"
              onChange={handleViewChange}
            />
            <FormControlLabel
              control={<Switch className="hide-switch" />}
              labelPlacement="end"
              label={<Typography className="outlet-switch-label">{t(`Map`, tOpts)}</Typography>}
            />
          </>
          :
          <ToggleButtonGroup
            sx={{ margin: '0% 3% 0% 3%' }}
            color="primary"
            value={activeView}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value={viewOptions.FiveCards.value} className='w70 h45 mt1'><span className='display-grid fs-xs'><span className='ml-10'><WindowIcon className={`${activeView === viewOptions.FiveCards.value ? 'color-primary' : 'color-black'}`} /></span><span className='color-black'>{viewOptions.FiveCards.text}</span></span></ToggleButton>
            <ToggleButton value={viewOptions.ThreeCards.value} className='w70 h45 mt1'><span className='display-grid fs-xs'><span className='ml-5'><SquareIcon className={`${activeView === viewOptions.ThreeCards.value ? 'color-primary' : 'color-black'}`} /></span><span className='color-black'>{viewOptions.ThreeCards.text}</span></span></ToggleButton>
            <ToggleButton value={viewOptions.MapView.value} className='w70 h45 mt1'><span className='display-grid fs-xs'><MapIcon className={`${activeView === viewOptions.MapView.value ? 'color-primary' : 'color-black'}`} /><span className='color-black'>{viewOptions.MapView.text}</span></span></ToggleButton>
          </ToggleButtonGroup>
        }
      </>
    )
  }

  useEffect(() => {
    const screenSize = window.innerWidth <= 540 ? 2 : window.innerWidth <= 912 ? 3 : window.innerWidth <= 1700 ? 4 : window.innerWidth >= 1800 ? columnCount : 6;
    setColumnCount(screenSize)
  }, [window.innerWidth, activeView])

  useEffect(() => {
    if (Object.keys(comboData)?.length <= 0) {
      utils.getComboData(undefined, userData);
    }
  }, [comboData]);

  useEffect(() => {
    clearFilters();
    return (() => {
      dispatch({
        type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
          filterButton: "",
          filterButtonName: "",
          printButtonName: "",
          clear: null,
          apply: null,
          customButtons: null,
          hidden: { search: true, operation: true, export: true, print: true, filter: true }
        }
      });
    })
  }, []);

  useEffect(() => {
    const counts = (outletList?.outlets?.length / columnCount) + 1;
    setRowCount(Math.round(counts))

    dispatch({
      type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
        filterButton: <FilterPanel filtersConfig={filtersConfig} getData={null} dateFilterMaxDate={dateFilterMaxDate} />,
        filterButtonName: 'Options',
        searchComponent: <VistaSearch />,
        clear: clearFilters,
        apply: refreshOutletData,
        customButtonsMiddle: headerButtonsMiddle,
        customButtonsRight: headerButtonsRight,
        hidden: { search: false, operation: true, export: true, print: true, filter: false }
      }
    });

  }, [outletList, filterValues, mapList, stateCombo, comboData]);

  useEffect(() => {
    if (initialLoad)
      refreshOutletData()
  }, [searchDebounce]);

  useEffect(() => {
    if (!comboData?.Country?.find(item => item.LookupId === '-2')) {
      comboData?.Country?.unshift({ LookupId: '-2', DisplayValue: 'All' });
    }
    if (!stateCombo?.find(item => item.LookupId === '-2')) {
      stateCombo?.unshift({ LookupId: '-2', DisplayValue: 'All' });
    }
  }, [comboData, stateCombo]);

  useEffect(() => {
    getOutletData({ startIndex: 0 });
  }, [])

  useEffect(() => {
    refreshOutletData();
  }, [toggleMaps, activeView, selectedClient]);

  function isRowLoaded({ index }) {
    return index < rowCount - 1;
  }

  function getFilter(filterParams) {
    const defaultValues = {
      IsActive: [1],
      IsSmart: [1, 255],
      Country: '-2',
      State: '-2',
      City: '',
      LastImageDateTime: 3,
      sortBy: "desc",
      daysFilter: '-2',
      fromDate: new Date(new Date().setDate(new Date().getDate() - 1)),
      toDate: dateFilterMaxDate
    }
    const filterObj = Object.keys(filterValues)?.length ? filterValues : defaultValues
    const { marketIds, classificationIds, channelIds, distributorIds, managerSelectedValue, IsSmart, IsActive, City, State, Country, LastImageDateTime, daysFilter, fromDate, toDate, sortBy } = filterObj;
    const whereObj = {};
    const sortObj = {};

    if (City !== '') {
      Object.assign(whereObj, { City: City });
    }
    if (State && Number(State) !== -2) {
      Object.assign(whereObj, { StateId: State });
    }
    if (Country && Number(Country) !== -2) {
      Object.assign(whereObj, { CountryId: Country });
    }
    if (classificationIds && Number(classificationIds) !== -1) {
      Object.assign(whereObj, { ClassificationId: classificationIds });
    }
    if (marketIds && Number(marketIds) !== -1) {
      Object.assign(whereObj, { MarketId: marketIds });
    }
    if (channelIds && Number(channelIds) !== -1) {
      Object.assign(whereObj, { LocationTypeId: channelIds });
    }
    if (distributorIds && Number(distributorIds) !== -1) {
      Object.assign(whereObj, { DistributorId: distributorIds });
    }
    if (managerSelectedValue && Number(managerSelectedValue) !== -1) {
      Object.assign(whereObj, { PrimaryRepId: managerSelectedValue });
    }
    if (IsSmart && Number(IsSmart) !== -1) {
      const smartCount = [...IsSmart]
      let index = smartCount.indexOf(255);
      if (index !== -1) {
        smartCount[index] = 0;
      }
      Object.assign(whereObj, { IsSmartCount: smartCount });
    }
    if (IsActive && Number(IsActive) !== -1) {
      Object.assign(whereObj, { "SubQuery.IsActive": IsActive });
    }
    if (LastImageDateTime && Number(LastImageDateTime) !== -1) {
      Object.assign(sortObj, { field: LastImageDateTime === 3 ? 'VerifiedOn' : 'SubQuery.Name', sort: LastImageDateTime === 1 ? 'asc' : 'desc' });
    }
    if ((daysFilter && fromDate && toDate) && (Number(daysFilter) !== -2)) {
      Object.assign(whereObj, { LastImageDateTime: { fromDate, toDate } });
    }
    if (vistaSearch) {
      Object.assign(filterParams, { outletSearch: vistaSearch });
    }
    filterParams.where = whereObj;
    if (Object.keys(sortObj)?.length > 0) {
      filterParams.sort = sortObj;
    }
    return filterParams;
  }

  async function loadMapList() {
    let params = { action: "mapList" }
    params = getFilter(params);
    setLoading(true);
    setMapList([]);
    dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true })
    await request({ url: apis.OutletList, params, history, dispatch, jsonPayload: true, disableLoader: true })
      .then((result) => {
        setTotalCount(result.totalCount);
        setMapList(result.list);
        if (result.totalCount == 0) {
          dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
        }
        setLoading(false);
      })
  }

  async function getOutletData({ startIndex, stopIndex }) {
    const startIndexL = startIndex * columnCount;
    try {
      setLoading(true);
      setTotalCount(outletList.totalCount);
      if (startIndexL > 0 && startIndexL >= totalCount) {
        return;
      }
      initialLoad = true;
      lastIndexRef.current = startIndexL;
      if (!isLoading.current) {
        isLoading.current = true;
        let params = {
          action: "list",
          pagination: {
            page: Math.ceil((startIndexL - 1) / MIN_BATCH_SIZE) + 1,
            pageSize: MIN_BATCH_SIZE
          }
        };
        params = getFilter(params);

        const response = await request({ url: apis.OutletList, params, history, dispatch, jsonPayload: true });
        outletList.totalCount = Number(response.totalCount);
        setTotalCount(outletList.totalCount);
        if (response.totalCount > 0) {
          if (response?.outlets?.length > 0) {
            if (startIndex === 0) {
              setOutletList(response);
            } else {
              const oldData = { ...outletList };
              for (const outlet of response.outlets) {
                oldData.outlets.push(outlet)
              }
              oldData.totalCount = response.totalCount;
              setOutletList(oldData);
            }
          }
        } else {
          setOutletList({ outlets: [], totalCount: 0 });
        }
        isLoading.current = false;
        setLoading(false);
      }
    } catch (error) {
      isLoading.current = false;
    }
  }


  function cellRenderer({ key, rowIndex, columnIndex, parent, style }) {
    let content;
    const index = (rowIndex * columnCount) + columnIndex, listL = outletList.outlets || []
    let cardStyle = { ...style };
    if (cardStyle?.height && cardStyle.height < LARGE_CARD_VIEW_SIZE && activeView === viewOptions.ThreeCards.value) {
      cardStyle.height = LARGE_CARD_VIEW_SIZE;
    }

    if (rowIndex <= rowCount - 1 && listL[index]) {
      const cellStyle = Object.assign({}, cardStyle, {
        minHeight: '200px',
      }),

        cellData = listL[index] || null;
      const isChestCooler = cellData.IsChestCooler;
      const className = `outlet-image-container ${activeView === viewOptions.ThreeCards.value ? 'outlet-image-container-three' : 'outlet-image-container-five'}`;
      const cardChildClassName = activeView === viewOptions.ThreeCards.value ? 'outlet-container-three' : 'outlet-container-five';
      const cardClassName = isMobile.mobile ? 'outlet-container-five-mobile' : cardChildClassName;
      const horizontalCoolerClassName = `outet-image-container-Vertical ${activeView === viewOptions.ThreeCards.value ? 'outet-image-container-Vertical-three' : 'outet-image-container-Vertical-five'}`;
      const imageChildClassName = `outlet-image ${activeView === viewOptions.ThreeCards.value ? 'outlet-image-three' : 'outlet-image-five'}`;
      let imageClassName = `cursor_pointer ${isChestCooler ? imageChildClassName : "outlet-image-Cam"} location-img`;
      const secondImageChildClassName = `outlet-image ${activeView === viewOptions.ThreeCards.value ? 'outlet-image-three' : 'outlet-image-five'}`;
      let secondImageClassName = `cursor_pointer ${isChestCooler ? secondImageChildClassName : "outlet-image-Cam"} location-img`;
      if (!isMobile.mobile && activeView === viewOptions.ThreeCards.value) {
        imageClassName += ' unstiched-image';
        secondImageClassName += ' unstiched-image';
      }

      let stitchedImageClassName = `cursor_pointer outlet-image ${activeView === viewOptions.ThreeCards.value ? 'outlet-image-three' : 'outlet-image-five'} location-img`;

      if (isChestCooler && activeView === viewOptions.ThreeCards.value) {
        stitchedImageClassName += ' outlet-horizontal-cooler-image';
      }

      content = <div key={cellData.UniqueGuid} style={cellStyle} className={cardClassName}>
        <Card className={`${activeView === viewOptions.ThreeCards.value ? 'm-2' : ''} ${isMobile.mobile ? 'outlet-card-mobile-margin' : ''} cursor_pointer outlet-card`} onClick={() => { openOutletCard(cellData) }}>
          <CardHeader
            className='outlet-card-header p-2'
            title={
              <span className="wrapped-text">{cellData.Name || "N/A"}</span>
            }
            subheader={
              <div className='subheader'>
                <span className="wrapped-text">{cellData.Code || "N/A"}</span>
              </div>
            }
          />
          {

            <CardContent className='pl-2 pr-2 pt-0 pb-0 text-center'>
              {cellData.IsStitched == 1 || cellData.AssetPurityId == null ? <div className={className}>
                <img
                  className={stitchedImageClassName}
                  src={`${apis.url}/Controllers/CoolerImagePreview.ashx?Guid=${cellData.UniqueGuid}&ImageType=Stitched&maxWidth=350`}
                  alt={cellData.LocationName}
                />
              </div> :
                <div className={!isChestCooler ? horizontalCoolerClassName : className}>
                  <img
                    className={imageClassName}
                    src={`${apis.url}/Controllers/Thumbnail.ashx?ImageId=${cellData.AssetPurityId}&ImageType=Cam1&maxWidth=${300}`}
                    alt={cellData.LocationName}
                  />
                  <div>
                    <img
                      className={secondImageClassName}
                      src={`${apis.url}/Controllers/Thumbnail.ashx?ImageId=${cellData.AssetPurityId}&ImageType=Cam2&maxWidth=${300}`}
                      alt={cellData.LocationName}
                    />
                  </div>
                </div>
              }
            </CardContent>
          }

          <CardActions className='mark-container'>
            <Typography variant='caption'>
              {`${t("Last Image Approved Date", tOpts) + ":"}`}{cellData.VerifiedOn ? dayjs(cellData.VerifiedOn).format(DateFormat) : t("None", tOpts)}
            </Typography>
          </CardActions>
        </Card>
      </div >
    } else if (columnIndex === 0) {
      content = <div style={cardStyle} />;
    } else {
      content = <div style={cardStyle} />;
    }
    return (
      <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={columnIndex} rowIndex={rowIndex}  >
        {content}
      </CellMeasurer>
    );
  }

  function onResize({ width }) {
    if (widthRef.current && (Math.abs(widthRef.current - width) > 300)) {
      cache.clearAll();
      if (grid) {
        grid.recomputeGridSize();
      }
      widthRef.current = width;
    } else if (!widthRef.current) {
      widthRef.current = width;
    }
  }

  function onSectionRendered({ rowStartIndex, rowStopIndex }) {
    onRowsRenderedG({
      startIndex: rowStartIndex,
      stopIndex: rowStopIndex
    });
  }

  function refreshOutletData() {
    if (toggleMaps) {
      loadMapList();
    } else {
      getOutletData({ startIndex: 0 });
    }
  }

  function clearFilters() {
    dispatch({
      type: actions.SET_FILTER_VALUES, filterValues: {
        IsActive: [1],
        IsSmart: [1, 255],
        Country: '-2',
        State: '-2',
        City: '',
        LastImageDateTime: 3,
        sortBy: "desc",
        daysFilter: '-2',
        fromDate: new Date(new Date().setDate(new Date().getDate() - 1)),
        toDate: dateFilterMaxDate
      }
    });
  }

  async function openOutletCard(outlet) {

    let params = {
      action: "CustomList", asArray: 0, limit: 10, start: 0, showAllImages: false, isFetchCoolerData: true,
      sort: 'AvgStockPercentage', dir: 'ASC'
    };

    params = { ...params, assetOrLocationName: '', isForRank: true, LocationId: outlet.LocationId }
    const outletData = await request({ url: apis.assetLatestImagesNew, params, history, dispatch });

    setOutletCard({
      modal: true,
      data: {
        assetId: outlet.AssetId,
        assetSerialNumber: outlet.AssetSerialNumber,
        locationName: outlet.LocationName || outlet.Name,
        code: outlet.Code,
        locationId: outlet.LocationId,
      },
      vistaData: outletData.records
    })
    dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false })
  }

  const filtersConfig = [
    {
      name: "Status",
      config: [
        {
          name: "IsSmart",
          label: "Smart",
          type: "checkSelect",
          options: [{ DisplayValue: "Yes", LookupId: 1 }, { DisplayValue: "No", LookupId: 255 }],
          size: 'small',
        },
        {
          name: "LastImageDateTime",
          type: "date",
          size: 'small',
          maxDate: dateFilterMaxDate,
          dateFormat: DateFormat.replace(/dd/i, "dd").replace(/yyyy/i, "yyyy"),
          hidden: false,
          defaultDay: constants.noneDateFilter,
          label: "Last image date",
          hideMenuLabel: true
        },
        {
          name: "IsActive",
          label: "Active",
          type: "checkSelect",
          multiSelect: false,
          checkUncheck: true,
          options: [{ DisplayValue: "Yes", LookupId: 1 }, { DisplayValue: "No", LookupId: 255 }],
          size: 'small',
        }
      ],

      openDefault: false
    },
    {
      name: "Group by",
      config: [
        {
          name: "marketIds",
          label: "Market",
          type: "checkSelect",
          titleMessage: 'Select & Unselect All',
          multiSelect: true,
          optionYesNo: true,
          checkUncheck: true,
          options: comboData.Market || [],
          size: 'small',
          hidden: false,
          className: 'outlet-markets',
          wrapText: true
        },
        {
          name: "channelIds",
          label: "Channel",
          type: "select",
          multiSelect: false,
          checkUncheck: false,
          options: comboData.LocationType || [],
          size: 'small',
          hidden: false,
          hideMenuLabel: true,
          wrapText: true
        },
        {
          name: "distributorIds",
          label: "Distributor",
          type: "select",
          multiSelect: false,
          checkUncheck: false,
          options: comboData.Distributor || [],
          size: 'small',
          hidden: false,
          hideMenuLabel: true,
          wrapText: true
        },
        {
          name: "classificationIds",
          label: "Classification",
          type: "select",
          multiSelect: false,
          checkUncheck: false,
          options: comboData.LocationClassification || [],
          size: 'small',
          hidden: false,
          hideMenuLabel: true,
          wrapText: true
        },
        {
          name: "managerSelectedValue",
          label: "Primary Sales Rep",
          type: "select",
          multiSelect: false,
          checkUncheck: false,
          options: formattedSalesPersonCombo || [],
          size: 'small',
          hidden: false,
          customPaperProps: {
            width: "195px",
            left: "1205px",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          hideMenuLabel: true,
          wrapText: true
        },
      ],

      openDefault: false
    },
    {
      name: "Location",
      config: [
        {
          name: "Country",
          label: "Country",
          type: "select",
          multiSelect: false,
          checkUncheck: false,
          options: comboData?.Country || [],
          size: 'small',
          hidden: false,
          hideMenuLabel: true
        },
        {
          name: "State",
          label: "State",
          type: "select",
          multiSelect: false,
          checkUncheck: false,
          options: stateCombo || [],
          size: 'small',
          hidden: false,
          hideMenuLabel: true
        },
        {
          name: "City",
          label: "City",
          type: "text",
          size: 'small',
          hidden: false,
          placeholder: 'City',
          hideMenuLabel: true
        },
      ],

      openDefault: false
    },
    {
      name: "Sort",
      config: [
        {
          name: "LastImageDateTime",
          label: "Sort",
          type: "select",
          multiSelect: false,
          checkUncheck: false,
          hideMenuLabel: true,
          disabled: (filterValues?.sortBy && filterValues.sortBy !== '-1') ? false : true,
          options: [{ DisplayValue: "Last Image Approved Date", LookupId: 3 }, { DisplayValue: "A-Z", LookupId: 1 }, { DisplayValue: "Z-A", LookupId: 2 }],
          size: 'small',
          hidden: false
        }
      ],

      openDefault: false
    },
  ]

  return (
    <div className={`${!isMobile.tablet ? (isMobile.portrait ? "mt-2" : "") : (isMobile.tablet && isMobile.portrait ? "mt-1" : "mt-n2")}`} container spacing={1} id="virtual-grid-container">
      {!toggleMaps ?
        <InfiniteLoader
          key={"Outlet-Page-InfiniteLoader-" + JSON.stringify(isMobile)}
          isRowLoaded={isRowLoaded}
          loadMoreRows={getOutletData}
          rowCount={rowCount}
          threshold={1} >
          {({ onRowsRendered, registerChild }) => {
            onRowsRenderedG = onRowsRendered;
            return (
              <WindowScroller>
                {({ height, scrollTop }) => (
                  <AutoSizer disableHeight onResize={onResize}>
                    {({ width }) => {
                      return outletList?.outlets?.length > 0 ?
                        <Grid
                          autoHeight
                          width={width}
                          height={height}
                          scrollTop={scrollTop}
                          ref={gridL => {
                            grid = gridL;
                            registerChild(gridL);
                          }}
                          columnCount={columnCount}
                          rowCount={rowCount}
                          columnWidth={width / columnCount}
                          rowHeight={cache.rowHeight}
                          cellRenderer={cellRenderer}
                          onSectionRendered={onSectionRendered}
                        />
                        : <div style={{ width: '200px' }}>{t("No record", tOpts)}</div>

                    }}
                  </AutoSizer>
                )}
              </WindowScroller>
            );
          }}
        </InfiniteLoader>
        :
        <OutletMap openOutletCard={openOutletCard} outletData={mapList} outletLoading={loading} />}

      {outletCard.modal && <OutletCard outletCard={outletCard} setOutletCard={setOutletCard} customClass={!isMobile.tablet && isMobile.mobile ? 'edit-order-mobile-overrides' : ""} />}

    </div>
  );
}

export default React.memo(Outlets);