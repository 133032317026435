import utils from "../utils";
import UiModel from "./ui-model";

const surveyInboxModel = new UiModel({
  title: "Survey Inbox",
  defaultSort: "SurveyTypeName ASC",
  linkColumn: "SurveyTypeName",
  titleDescription: "Review submitted surveys individually",
  api: "Survey",
  module: "SurveyInbox",
  pageTitle: "Survey Inbox Overview",
  readOnly: true,
  overrideFileName: "Survey Inbox",
  addCreatedOnColumn: false,
  addCreatedByColumn: false,
  addModifiedOnColumn: false,
  addModifiedByColumn: false,
  rowRedirectLink: "/SurveyInbox/${LocationId}",
  addRecordToState: true,
  showExportWithDetails: true,
  customExportColumns: {
    SurveyId: { field: 'SurveyId', headerName: 'Survey Id', keepUTC: false, type: 'string', width: 240 },
    SurveyName: { field: 'SurveyName', headerName: 'Survey Name', keepUTC: false, type: 'string', width: 240 },
    SurveyCategory: { field: 'SurveyCategory', headerName: 'Survey Category', keepUTC: false, type: 'string', width: 240 },
    Questionnaires: { field: 'Questionnaires', headerName: 'Questionnaires', keepUTC: false, type: 'string', width: 240 },
    OutletName: { field: 'OutletName', headerName: 'Outlet Name', keepUTC: false, type: 'string', width: 240 },
    OutletCode: { field: 'OutletCode', headerName: 'Outlet Code', keepUTC: false, type: 'string', width: 240 },
    Market: { field: 'Market', headerName: 'Market', keepUTC: false, type: 'string', width: 240 },
    Channel: { field: 'Channel', headerName: 'Channel', keepUTC: false, type: 'string', width: 240 },
    Classification: { field: 'Classification', headerName: 'Classification', keepUTC: false, type: 'string', width: 240 },
    Latitude: { field: 'Latitude', headerName: 'Latitude', keepUTC: false, type: 'number', width: 240 },
    Longitude: { field: 'Longitude', headerName: 'Longitude', keepUTC: false, type: 'number', width: 240 },
    SectionId: { field: 'SectionId', headerName: 'Section Id', keepUTC: false, type: 'string', width: 240 },
    SectionTitle: { field: 'SectionTitle', headerName: 'Section Title', keepUTC: false, type: 'string', width: 240 },
    QuestionId: { field: 'QuestionId', headerName: 'Question Id', keepUTC: false, type: 'string', width: 240 },
    DependentQuestion: { field: 'DependentQuestion', headerName: 'Dependent Question', keepUTC: false, type: 'string', width: 240 },
    QuestionText: { field: 'QuestionText', headerName: 'Question Text', keepUTC: false, type: 'string', width: 240 },
    AnswerKey: { field: 'AnswerKey', headerName: 'Answer Key', keepUTC: false, type: 'string', width: 240 },
    Answer: { field: 'Answer', headerName: 'Answer', keepUTC: false, type: 'string', width: 240 },
    Attachment: { field: 'Attachment', headerName: 'Attachment', keepUTC: false, type: 'string', width: 240 },
    SurveyDateTime: { field: 'SurveyDateTime', headerName: 'Survey Date Time', keepUTC: false, type: 'string', width: 240 },
    User: { field: 'User', headerName: 'User', keepUTC: false, type: 'string', width: 240 }
  },
  columns: [
    { field: "SurveyTypeId", type: "number", hide: true, width: 80, label: "ID", fieldLabel: null, pinned: false },
    { field: "SurveyName", type: "string", label: "Survey Name", width: 240, pinned: true, link: true, linkTo: "/SurveyInbox/${LocationId}" },
    { field: "SurveyType", type: "string", label: "Questionnaires", width: 240, pinned: false, link: false, hide: false },
    { field: "OutletName", type: "string", label: "Outlet Name", width: 240, pinned: false, link: false },
    { field: "OutletCode", type: "string", label: "Outlet Code", width: 240, pinned: false, link: false, hide: false },
    { field: "MarketName", type: "string", label: "Markets", width: 240, pinned: false, link: false, hide: true },
    { field: "Channel", type: "string", label: "Channels", width: 240, pinned: false, link: false, hide: true },
    { field: "Classification", type: "string", label: "Classifications", width: 240, pinned: false, link: false, hide: true },
    { field: "Latitude", type: "number", label: "Latitude", width: 240, pinned: false, link: false, hide: true },
    { field: "Longitude", type: "number", label: "Longitude", width: 240, pinned: false, link: false, hide: true },
    { field: "SurveyCategory", type: "string", label: "Survey Category", width: 240, pinned: false, link: false, hide: true },
    { field: "EndDate", type: "dateTime", label: "Survey Date Time", width: 240, pinned: false, link: false, keepUTC: true },
    { field: "ModifiedByUser", type: "string", label: "User", width: 240, pinned: false, link: false }
  ],
});

export default surveyInboxModel;
