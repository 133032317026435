import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, FormControl, FormHelperText } from '@mui/material';
import { apis, request } from '../../../httpUtil';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const Field = ({ column, field, formik, lookups, otherProps }) => {
    const userData = useSelector(state => state.appReducer.userData);
    const { ClientId } = userData && userData.tags ? userData.tags : 0;
    const initialOptions = lookups ? lookups[column?.lookup] : [];
    let initialInputValue = formik.values[field]?.length > 1 ? (formik.values[field]?.split(", ")?.map(Number) || []) : ([formik.values[field]] || []);
    const [options, setOptions] = useState(initialOptions);
    const [inputValue, setInputValue] = useState(initialInputValue);
    let value;
    if (Object.entries(formik?.values).length > 0) {
        value = initialOptions?.filter(option => formik?.values?.AssignedToUserId === option.value) || [];
    }
    const [selectedOption, setSelectedOption] = useState(value || '');
    const dispatch = useDispatch();
    const history = useHistory();
    if (column?.selectField) {
        field = column.selectField;
    }
    const filterOptions = (options, state) => {
        return options.filter(option => option.label.toLowerCase().startsWith(state.inputValue.toLowerCase()))
    }
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const params = { start: 0, limit: 50, comboType: 'ClientUserType', asArray: 0, query: inputValue, ClientId: ClientId };
                const result = await request({ url: apis.Combo, params: params, history, dispatch });
                if (result && result.records && result.records.length > 0) {
                    setOptions(result.records.map(item => ({ label: item.DisplayValue, value: item.LookupId })));
                } else {
                    setOptions([]);
                }
            } catch (error) {
                window.onerror({ url: apis.SendErrorEmail, errorMsg: `Error fetching options: ${error}`, appName: 'Playbook' })
                setOptions([]);
            }
        };
        fetchOptions();
    }, [inputValue]);

    useEffect(() => {
        if (formik.values) {
            let option;
            if (initialOptions) {
                option = initialOptions.find(option => option.value === formik.values[field]);
            } else if (options) {
                option = options.find(option => option.value === formik.values[field]);
            }
            setSelectedOption(option);

        }
    }, [formik.values, options]);

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        setOptions([]);
    };

    const handleChange = (event, newValue) => {
        formik?.setFieldValue(column?.selectField, newValue?.value || '');
        setSelectedOption(newValue);
        setInputValue(newValue ? newValue.label : '');
    };
    return (
        <FormControl fullWidth variant="standard" error={formik.touched[field] && Boolean(formik.errors[field])}>
            <Autocomplete
                {...otherProps}
                options={options}
                getOptionLabel={(option) => option.label}
                filterOptions={filterOptions}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                value={selectedOption}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                    />
                )}
            />
            {formik.touched[field] && formik.errors[field] && <FormHelperText>{formik.errors[field]}</FormHelperText>}
        </FormControl>
    );
};

export default Field;
