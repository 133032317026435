export const handleGlobalError = async function ({ url, errorMsg, errorObj, appName }) {
  if (!navigator.onLine) {
    return;
  }

  const isLocal = /^(https?:\/\/)?(localhost|127\.0\.0\.1|::1)(:\d+)?(\/|$)/i.test(document.URL);
  if (isLocal) {
    return;
  }

  const params = {
    rawUrl: window.location.href.replace(/&/g, "_"),
    stack: errorObj?.stack ?? (errorObj || errorMsg),
    fileName: appName
  };

  try {
    await fetch(`${url}`, {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(params), // Ensuring params is correctly stringified
      credentials: 'include'
    });
  } catch (error) {
    console.error('Failed to send error log:', error.message);
  }
  return false;
};

export const initialize = function (url, appName) {
  window.onerror = (errorMsg, rawUrl, line, col, errorObj) =>
    handleGlobalError({ url, errorMsg, errorObj, appName });
  window.onunhandledrejection = (event) =>
    handleGlobalError({ url, errorMsg: event.reason?.message, errorObj: event.reason, appName });
};
