import {
  Card, CardContent, Grid, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Tooltip,
  Menu, MenuItem, Divider, Tabs, Tab,
  IconButton
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { apis, request } from "../../httpUtil";
import actions from "../../redux/actions";
import utils from "../../utils";
import FilterPanel from '../filterPanel';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import GridBase from "../GridBase";
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear.js'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { InfoRounded } from '@material-ui/icons';
import { ProgressChartsSingleKPI, ProgressCharts, GlobalCharts } from "./kpiCharts"
import swal from 'sweetalert';
import kpiDescription from '../../utils/kpiDescription.json'
import InfoBar from './infoAndFilterBar'
import constants from '../../utils/constants'
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import OutletCard from '../Outlets/outletCard';
import useMobile from "../../utils/useMobile";
import { useTranslation } from 'react-i18next';
dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear)
const { groupByOptionsIB, periods, prPeriodOptions, prNPeriodOptions, groupByDisplayName, dateFormat, customOrder, salesAndShopper } = constants;
const { kpiUnits, kpiNames, scrollToBottom } = utils;

const tableColumns = {
  locationTableColumns: [
    { id: 'Name', label: 'Outlet Name', minWidth: 300, flex: 0, hideable: false, },
    { id: 'Street', label: 'Address', minWidth: 300, flex: 0 },
    { id: 'City', label: 'City', minWidth: 100, flex: 0 },
    { id: 'State', label: 'State', minWidth: 100, flex: 0 }
  ],
  imageTableColumn: [
    { id: 'SerialNumber', label: 'Asset Serial', minWidth: 200, flex: 0, hideable: false, }
  ]
}

const tabConfig = {
  '1': {
    drillDown: true,
    leagueTable: false,
    infoBar: true,
    tableMenuDisable: false
  },
  '0': {
    drillDown: false,
    leagueTable: false,
    infoBar: true,
    tableMenuDisable: true
  }
}

const getDefaultTableConfig = (header) => {
  return {
    headersData: [
      {
        colSpan: 0,
        data: [
          {
            colspan: 0,
            label: header
          }
        ]
      }
    ],
    contentData: []
  }
}

const progressIcons = {
  'neutral': <RemoveIcon className="ml-2" fontSize="small" style={{ color: 'rgba(201, 203, 207)' }} />,
  'up': <ArrowUpwardIcon className="ml-2" fontSize="small" style={{ color: '#57C980' }} />,
  'down': <ArrowDownwardIcon className="ml-2" fontSize="small" style={{ color: '#E74231' }} />
}

export const defaultTableConfigMenuOptions = [
  {
    items: [
      {
        label: "Drill down group by Market",
        value: {
          groupBy: "MarketId",
        },
        useInSurvey: true
      },
      {
        label: "Drill down group by Classification",
        value: {
          groupBy: "ClassificationId"
        },
        useInSurvey: true
      },
      {
        label: "Drill down group by Channel",
        value: {
          groupBy: "LocationTypeId"
        },
        useInSurvey: true
      },
      {
        label: "Drill down group by Sales Rep",
        value: {
          groupBy: "PrimaryRepId"
        },
      }]
    ,
    useInSurvey: true,
    drillDown: true
  },
  {
    items: [
      {
        label: "League table group by Location - Top 10",
        value: {
          groupBy: "Location.LocationId",
          sort: "DESC"
        }
      },
      {
        label: "League table group by Location - Bottom 10",
        value: {
          groupBy: "Location.LocationId",
          sort: "ASC"
        }
      },
      {
        label: "League table group by Market - Top 10",
        value: {
          groupBy: "MarketId",
          sort: "DESC"
        }
      },
      {
        label: "League table group by Market - Bottom 10",
        value: {
          groupBy: "MarketId",
          sort: "ASC"
        }
      },
      {
        label: "League table group by Classification - Top 10",
        value: {
          groupBy: "ClassificationId",
          sort: "DESC"
        }
      },
      {
        label: "League table group by Classification - Bottom 10",
        value: {
          groupBy: "ClassificationId",
          sort: "ASC"
        }
      },
      {
        label: "League table group by Channel - Top 10",
        value: {
          groupBy: "LocationTypeId",
          sort: "DESC"
        }
      },
      {
        label: "League table group by Channel - Bottom 10",
        value: {
          groupBy: "LocationTypeId",
          sort: "ASC"
        }
      }
    ],
    leagueTable: true,
    showDivider: false
  }
]

export const SummaryTable = ({ tableConfig = {}, onDrillDown = null, onDrillMenu = null, selectedKPI = null }) => {
  const { headersData, contentData } = tableConfig;
  const t = utils.t
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  return (<TableContainer className="KPI-Grid-Class" component={Paper} key={`-tableContainer`}>
    <Table stickyHeader size="small">
      <TableHead>
        {headersData.map((tableHeaders, index) => {
          return <TableRow key={index}>
            {tableHeaders?.data?.map((headerCell, cellIndex) => {
              return <TableCell
                style={headerCell.cellColor ? { backgroundColor: headerCell.cellColor } : {}}
                key={cellIndex}
                className="border data-grid-font-12"
                align={headerCell.align || "center"}
                size="small"
                padding="normal"
                rowSpan={headerCell.rowspan}
                colSpan={headerCell.colspan}>
                {typeof (headerCell.label) === 'string' ? t(headerCell.label, tOpts) : headerCell.label}
              </TableCell>
            })}
          </TableRow>
        })}
      </TableHead>
      <TableBody onDoubleClick={onDrillDown} >
        {contentData.map((contentData, contentIndex) => {
          return <TableRow key={contentIndex} id={`summary-table-row-${contentIndex}`}>
            {contentData.data.map((cellData, cellIndex) => {
              const style = selectedKPI === cellData.kpiName ? { backgroundColor: 'rgba(201, 203, 207, 0.4)' } : cellData.cellColor ? { backgroundColor: cellData.cellColor } : {};
              return <TableCell
                style={style}
                size="small"
                padding="normal"
                key={cellIndex}
                align={cellData.align || "center"}
                rowSpan={cellData.rowspan}
                colSpan={cellData.colspan}
                className={`cursor_pointer kpi-grid-cell border-right data-grid-font-12`}
                data-col={cellData.key}
                data-label={cellData.label}
                data-kpiName={cellData.kpiName || ''}
                data-kpiDisplayName={cellData.kpiDisplayName || ''}
                data-groupByKeyValue={cellData.groupByKeyValue || ''}
                data-groupByKeyName={cellData.groupByKeyName || ''}
                data-colIndex={cellIndex} >
                {typeof (cellData.label) === 'string' ? t(cellData.label, tOpts) : cellData.label}
              </TableCell>
            })}
          </TableRow>
        })}
      </TableBody>
    </Table>
  </TableContainer>
  )
}

export const DefaultTableMenu = function ({ label, kpiName, kpiDisplayName, onDrillMenu, componentConfig = {}, menuOptions = defaultTableConfigMenuOptions }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const filterValues = useSelector((state) => state.appReducer.filterValues) || {};
  const dispatch = useDispatch();
  const t = utils.t
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClick = (value) => {

    const event = {
      fromMenu: true,
      target: {
        dataset: {
          kpiname: t(kpiName, tOpts),
          kpidisplayname: t(kpiDisplayName, tOpts)
        },
        filters: {
          groupBy: value.groupBy,
          sort: value.sort
        }
      },
    }

    if (onDrillMenu) {
      onDrillMenu(event);
    }

    handleClose();
    const filterObj = { ...filterValues };
    filterObj.groupBy = value.groupBy;
    filterObj.changedKey = "";
    dispatch({ type: actions.SET_FILTER_VALUES, filterValues: filterObj });
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <div className='w-100'>
      <div
        aria-label="more"
        id="long-button"
        className='w-100'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick} >
        {t(kpiDisplayName, tOpts)}
      </div>
      {!componentConfig.tableMenuDisable && <Menu
        id="long-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button', }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { maxHeight: 48 * 4.5, width: 'auto', } }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }} >
        {(componentConfig.global) ?
          <MenuItem key={'Drill down group by Client'} onClick={() => handleMenuClick({ groupBy: "AssetPurity.ClientId" })}>
            {t('Drill down group by Client', tOpts)}
          </MenuItem> : <>
            {menuOptions.map((categories) => {
              if (!((componentConfig.drillDown && categories.drillDown) || (componentConfig.leagueTable && categories.leagueTable))) {
                return null;
              }
              return <>
                {categories.showDivider && <Divider />}
                {categories.items.map((menuItem) => {
                  return <MenuItem key={menuItem.label} onClick={() => handleMenuClick(menuItem.value)}>
                    {t(menuItem.label, tOpts)}
                  </MenuItem>
                })}
              </>
            })}
          </>}
      </Menu>}
    </div>
  )
}

let summaryDrillDownTableConfig = [], locationTableConfig = [], imageTableConfig = [], isPlanogramCompliance = false;
const instantFilters = ['selectedMetrics', 'daysFilter', 'fromDate', 'toDate', 'prNPeriod', 'prPeriod', 'groupBy'], metricFilterKey = "selectedMetrics",
  dateFilterMaxDate = new Date(new Date().setDate(new Date().getDate() - 1)),
  metricsCategoryMappings = utils.metricsCategoryMappings();

function Merchandising({ t: trans, i18n, kpiCategory }) {
  const { t } = utils;
  const tOpts = { t: trans, i18n };
  const history = useHistory(), dispatch = useDispatch();
  const loaderOpen = useSelector(state => state.appReducer.loaderOpen);
  const clientsList = useSelector(state => state.appReducer.clientsList);
  const selectedClient = useSelector(state => state.appReducer.selectClient);
  const comboData = useSelector(state => state.appReducer.comboData);
  const filterValues = useSelector(state => state.appReducer.filterValues);
  const userData = useSelector(state => state.appReducer.userData);

  const [activeTab, setActiveTab] = React.useState(0);
  const [componentConfig, setComponentConfig] = useState(tabConfig[activeTab + '']);
  const [summaryData, setSummaryData] = useState({});
  const [chartData, setChartData] = useState({ chartData: {}, chartKey: "DC_1" });
  const [loading, setLoading] = useState({ filter: false, api: false });
  const [summaryDrillDown, setSummaryDrillDown] = useState({});
  const [leagueSummaryDrillDown, setLeagueSummaryDrillDown] = useState({});
  const [locationDrillDown, setLocationDrillDown] = useState({});
  const [imageDrillDown, setImageDrillDown] = useState({});
  const [tableConfig, setTableConfig] = useState({})
  const [infoBarData, setInfoBarData] = useState({})
  const [chartInfo, setChartInfo] = useState({ chartData: {}, chartKey: "PC_1" })
  const [prGridParams, setPrGridParams] = useState({});
  const [sortModal, setSortModal] = useState({})
  const [mappedFilterValue, setMappedFilterValue] = useState({});
  const [localCombo, setLocalCombo] = useState(comboData)
  const [outletCard, setOutletCard] = useState({ modal: false, data: "" });
  const metricsRef = React.useRef([]);
  const filterValuesRef = React.useRef(filterValues);
  const customColumnRef = React.useRef(false);
  const comboDataRef = React.useRef(comboData);
  const sortModalRef = React.useRef({});
  const singleKPIRef = React.useRef({ status: false });
  const mobile = useMobile()

  const onSortModelChange = (newModel) => {
    setSortModal(newModel[0]);
    sortModalRef.current = newModel[0];
  };
  const current = dayjs();
  const dayOfWeek = current.day();

  const clientDates = {
    startOfWeek: dayOfWeek === 0 ? current.subtract(current.day() + 6, 'day').startOf('day') : dayjs().startOf('week'),
    startOfMonth: dayjs().startOf('month'),
    startOfQuarter: dayjs().startOf('quarter'),
    startOfYear: dayjs().startOf('year'),
    todayDate: dayjs(new Date().setHours(0, 0, 0, 0))
  }

  const metricOptionsIB = metricsRef.current.map((item) => { return { LookupId: item.dataKey, DisplayValue: item.label } })
  metricOptionsIB.unshift({ LookupId: 'allKPI', DisplayValue: t('All KPI') })

  useEffect(() => {
    const data = async () => {
      const result = await request({ url: apis.KPIFilter, history, dispatch, jsonPayload: true });
      if (!result.error) {
        setMappedFilterValue(result);
      }
    }
    data();

    return () => {
      clearFilters();
      dispatch({
        type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
          filterButton: null,
          clear: null,
          apply: null,
          hidden: { search: true, operation: false, export: false, print: false, filter: true }
        }
      });
      dispatch({ type: actions.SHOW_VISTA_DETAIL_POP_UP, openVistaDetail: false })

      utils.removeBackButton(dispatch);
    }
  }, [])

  useEffect(() => {
    if (Object.keys(comboData).length) {
      setLocalCombo(prev => ({ ...prev, ...comboData }));
    }
  }, [comboData])

  useEffect(() => {
    const { channelIds = [], classificationIds = [], marketIds = [], changedKey = '' } = filterValues || {}
    const { Market, LocationClassification, LocationType } = comboData;
    const { market, classification, location } = mappedFilterValue;
    if (!(market && classification && location)) {
      return;
    }
    if (!(classificationIds.length || marketIds.length || channelIds.length)) {
      setLocalCombo(prev => ({ ...prev, ...{ Market, LocationClassification, LocationType } }));
      return;
    }
    if (classificationIds[0] === "-1" && marketIds[0] === "-1" && channelIds[0] === "-1") {
      setLocalCombo(prev => ({ ...prev, ...{ Market, LocationClassification, LocationType } }));
      return;
    }

    let tempMarkets = {}, tempLocation = {}, tempClassification = {};
    if (marketIds[0] !== "-1") {
      for (let marketId of marketIds) {
        const record = Market.find((value) => value.LookupId === marketId)
        if (record) {
          const { LocationType: locationList = [], Classification = [] } = market[record.DisplayValue] || {};
          for (let item of LocationType) {
            if (!locationList.includes(item.DisplayValue)) {
              continue
            }
            tempLocation[item.DisplayValue] = item;
          }
          for (let item of LocationClassification) {
            if (!Classification.includes(item.DisplayValue)) {
              continue
            }
            tempClassification[item.DisplayValue] = item;
          }
        }
      }
    }
    if (classificationIds[0] !== "-1") {
      for (let classificationId of classificationIds) {
        const record = LocationClassification.find((value) => value.LookupId === classificationId)
        if (record) {
          const { LocationType: locationList = [], MarketName = [] } = classification[record.DisplayValue] || {};
          for (let item of LocationType) {
            if (!locationList.includes(item.DisplayValue)) {
              continue
            }
            tempLocation[item.DisplayValue] = item;
          }
          for (let item of Market) {
            if (!MarketName.includes(item.DisplayValue)) {
              continue
            }
            tempMarkets[item.DisplayValue] = item;
          }
        }
      }
    }
    if (channelIds[0] !== "-1") {
      for (let channelId of channelIds) {
        const record = LocationType.find((value) => value.LookupId === channelId)
        if (record) {
          const { MarketName = [], Classification = [] } = location[record.DisplayValue] || {};
          for (let item of Market) {
            if (!MarketName.includes(item.DisplayValue)) {
              continue
            }
            tempMarkets[item.DisplayValue] = item;
          }
          for (let item of LocationClassification) {
            if (!Classification.includes(item.DisplayValue)) {
              continue
            }
            tempClassification[item.DisplayValue] = item;
          }
        }
      }
    }

    //market
    if (changedKey !== "marketIds") {
      const useAllValues = (!classificationIds[0] || classificationIds[0] === "-1") && (!channelIds[0] || channelIds[0] === "-1");
      localCombo["Market"] = useAllValues ? Market : Object.values(tempMarkets);
    }
    //Classification
    if (changedKey !== "classificationIds") {
      const useAllValues = (!marketIds[0] || marketIds[0] === "-1") && (!channelIds[0] || channelIds[0] === "-1");
      localCombo["LocationClassification"] = useAllValues ? LocationClassification : Object.values(tempClassification);
    }
    //channels
    if (changedKey !== "channelIds") {
      const useAllValues = (!marketIds[0] || marketIds[0] === "-1") && (!classificationIds[0] || classificationIds[0] === "-1");
      localCombo["LocationType"] = useAllValues ? LocationType : Object.values(tempLocation);
    }
    setLocalCombo(prev => ({ ...prev, ...localCombo }));
  }, [filterValues, comboData])

  useEffect(() => {
    if (filterValues) {
      filterValuesRef.current = filterValues;
      const selectedMetrics = filterValuesRef.current.selectedMetrics;
      if (selectedMetrics === 'allKPI') {
        metricsRef.current = metricsRef.current.map((item) => {
          item.checked = true;
          return item;
        })
        singleKPIRef.current = { status: false };
      } else {
        let displayName = '';
        metricsRef.current = metricsRef.current.map((item) => {
          if (selectedMetrics === item.dataKey) {
            displayName = item.label;
            item.checked = true;
          } else {
            item.checked = false;
          }
          return item;
        })
        singleKPIRef.current = { status: true, kpiName: selectedMetrics, kpiDisplayName: displayName };
      }

      if (instantFilters.includes(filterValues?.changedKey)) {
        getSummaryData();
      }
      if (filterValues.changedKey === metricFilterKey) {
        setChartInfo({ chartData: {}, chartKey: "PC_1" })
      }
    }
    if (comboData) {
      comboDataRef.current = comboData;
    }
  }, [filterValues])

  useEffect(() => {
    const filteredConfig = filtersConfig.filter((value) => value.visible);
    dispatch({
      type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
        filterButton: <FilterPanel filtersConfig={filteredConfig} getData={null} />,
        filterButtonName: "Options",
        printButtonName: "Export to PDF",
        clear: clearFilters,
        apply: getSummaryData,
        hidden: { search: true, operation: true, export: true, print: false, filter: false }
      }
    });
  }, [comboData, localCombo])

  useEffect(() => {
    (async () => {
      if (clientsList && clientsList.length) {
        await loadMetrics();
        clearFilters()
        setLoading({ ...loading, filter: true });
      }
    })();
  }, [clientsList])

  useEffect(() => {
    if (filterValues && Object.keys(filterValues).length && loading.filter && !loading.api) {
      filterValuesRef.current.activePeriods = ['Custom'];
      setLoading({ ...loading, api: true });
      getSummaryData();
    }
  }, [filterValues, loading])

  useEffect(() => {
    if (summaryData && Object.keys(summaryData).length) {
      if (activeTab === 0) {
        prepareDataForProgressTable(summaryData)
      } else {
        prepareDataForTable(summaryData)
      }
    }
  }, [summaryData]);

  async function loadMetrics(prop = {}) {

    if (kpiCategory === "SalesAndShopper") {
      metricsRef.current = salesAndShopper;
      return;
    }

    const { useSort = false } = prop;
    const response = await utils.getMetricFilters({ history, dispatch });
    if (response && response.length) {
      let metricDB = [];
      for (const element of response) {
        if (element.dashboardView) {
          metricDB.push({
            checked: (componentConfig.global && element.dataKey === kpiNames.salesOpportunity) ? false : true,
            dataKey: element.dataKey,
            category: t(metricsCategoryMappings[element.dataKey], tOpts),
            label: element.label,
            metricsValue: (element.dataKey === kpiNames.salesOpportunity) ? "Sum" : "Avg",
            ranges: []
          })
        }
      }

      if (useSort) {
        metricDB.sort(function (first, second) {
          const firstObj = first.label.toUpperCase(), secondObj = second.label.toUpperCase();
          return (firstObj < secondObj) ? -1 : (firstObj > secondObj) ? 1 : 0;
        });
      } else {
        let order = [];
        for (let item of customOrder) {
          const val = metricDB.find(v => v.label === item);
          if (val) {
            order.push(val)
          }
        }
        metricDB = order;
      }

      metricsRef.current = metricDB;
    } else {
      return swal({
        title: t('Unable to get KPI List', tOpts),
        text: "",
        icon: "info",
        dangerMode: false
      });
    }
  }

  function clearFilters() {
    let weekDate = new Date();
    weekDate = weekDate.getDate() - 7;
    dispatch({
      type: actions.SET_FILTER_VALUES, filterValues: {
        activePeriods: ['Custom'],
        daysFilter: "7:day",
        fromDate: new Date(new Date().setDate(weekDate)),
        toDate: dateFilterMaxDate,
        groupBy: 'MarketId',
        clientIds: [selectedClient],
        selectedMetrics: 'allKPI',
        prNPeriod: 4,
        prPeriod: "Week"
      }
    });
  }

  useEffect(() => {
    if (Object.keys(filterValues || {}).length === 0) {
      clearFilters()
    }

  }, [filterValues])

  const sortComparator = {
    custom: (v1, v2) => {
      let key = sortModalRef.current.field.split('_')[0];
      key = key.replace("$", "");
      let firstValue = parseFloat(Number(v1.value).toFixed(1));
      let secondValue = parseFloat(Number(v2.value).toFixed(1));
      if (firstValue === 0 && v1.row[key + "_Images"] === 0) {
        firstValue = null;
      }
      if (secondValue === 0 && v2.row[key + "_Images"] === 0) {
        secondValue = null;
      }

      let result;
      if (sortModalRef.current.sort === 'asc') {
        result = ((secondValue != null) - (firstValue != null)) || (firstValue - secondValue);
      } else {
        result = ((firstValue != null) - (secondValue != null)) || (firstValue - secondValue);
      }
      return result;
    }
  }

  const getSummaryData = useCallback(async (params) => {

    if (filterValuesRef?.current?.activePeriods?.length === 0 || (filterValuesRef?.current?.activePeriods?.length === 1 && filterValuesRef?.current?.activePeriods[0] === '-1')) {
      filterValuesRef.current.activePeriods.push('Custom');
      dispatch({ type: actions.SET_FILTER_VALUES, filterValues: filterValuesRef.current });
    }
    const { marketIds, classificationIds, distributorIds, channelIds, fromDate, toDate, managerSelectedValue, prNPeriod, prPeriod, groupBy } = filterValuesRef.current || {};
    const filters = utils.getFilters({
      kpiFilters: { marketIds, classificationIds, distributorIds, channelIds, fromDate, toDate, managerSelectedValue },
      groupBy: [],
      metrics: metricsRef.current,
      countBy: singleKPIRef.current.status ? groupBy : "",
      dateFormat: utils.dateFormat,
      timeZone: userData?.tags?.NodaTimeZone || ''
    });
    const prPeriodsL = [], prParameters = {};
    let prStart = '', prEnd = '';
    if (activeTab === 0) {
      let selectedPeriod = dayjs(new Date().setHours(0, 0, 0, 0));
      for (let i = 0; i <= Number(prNPeriod); i++) {
        let start = '', end = '';
        if (i === 0) {
          start = dayjs(selectedPeriod).startOf(prPeriod.toLowerCase());
          end = dayjs(new Date().setHours(0, 0, 0, 0));
          prEnd = end;
        } else {
          start = dayjs(selectedPeriod).subtract(2, 'day').startOf(prPeriod.toLowerCase());
          end = dayjs(selectedPeriod).subtract(2, 'day').endOf(prPeriod.toLowerCase());
        }
        if (i === Number(prNPeriod)) {
          prStart = start;
        }
        selectedPeriod = start;
        prParameters[`StartW${i}`] = { value: start.format(utils.dateFormat) };
        prParameters[`EndW${i}`] = { value: end.format(utils.dateFormat) };
        prPeriodsL.push({ name: `${prPeriod}_${i}`, paramStart: `StartW${i}`, paramEnd: `EndW${i}` });
      }
      filters.action = 'progressReport';
      filters.lastNWeeks = Number(prNPeriod);
      filters.prPeriods = prPeriodsL;
      filters.prStart = prStart;
      filters.prEnd = prEnd;
      filters.prParameters = prParameters;
    }

    if (filterValuesRef?.current?.clientIds?.length) {
      filters.clientList = Number.isNaN(filterValuesRef.current.clientIds[0]) ? [0] : filterValuesRef.current.clientIds;
    }

    if (componentConfig.global) {
      filters.action = 'globalReport'
    }

    try {
      filters.clientDates = clientDates;
      filters.activePeriods = [...filterValuesRef?.current?.activePeriods];
      filters.isSingleKPI = singleKPIRef.current.status;
      filters.summaryChart = false;

      let data = {}
      if (activeTab === 0 && (prPeriod === 'Quarter' || prPeriod === 'Year')) {
        const allData = [];

        if (prNPeriod > 1) {
          dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
          for (let i = 0; i < prPeriodsL.length; i++) {
            const result = await request({ url: apis.KPISummary, params: { ...filters, prPeriods: [prPeriodsL[i]] }, history, dispatch, jsonPayload: true, disableLoader: true, keepLoading: true })
            allData.push(result)
          }
          const combinedSingleData = [];
          for (let i = 0; i < allData.length; i++) {
            if (allData[i].data) {
              for (let j = 0; j < allData[i].data.length; j++) {
                combinedSingleData[j] = { ...combinedSingleData[j], ...allData[i].data[j] };
              }
            }
            data = {
              ...data,
              chartData: { ...data.chartData, ...allData[i].chartData },
              kpiData: { ...data.kpiData, ...allData[i].kpiData },
              infoBarData: { ...data.infoBarData, ...allData[i].infoBarData },
              options: { ...data.options, ...allData[i].options },
              prParameters: { ...data.prParameters, ...allData[i].prParameters }
            }
          }
          if (combinedSingleData.length) {
            data.data = combinedSingleData;
          }
          dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
        }
      } else {
        data = await request({ url: apis.KPISummary, params: filters, history, dispatch, jsonPayload: true });
      }

      if (data) {
        if (singleKPIRef.current.status) {
          if (activeTab === 0) {
            prepareDataForSingleKPIProgress(data)
          } else {
            prepareDataForSingleKPI({ data })
          }
        } else {
          if (activeTab === 0 && data?.options?.prParameters) {
            setPrGridParams({ prParameters: data.options.prParameters })
          }
          kpiUnits.SalesOpportunity = data?.options?.currency;
          isPlanogramCompliance = (data?.options?.planogramComplianceEnabled || false);
          setSummaryData(data?.kpiData || {});
          setInfoBarData(data?.infoBarData || {});
          setChartData({ chartData: data?.chartData, chartKey: ('summaryChartKey' + Math.floor(Date.now() / 1000)) });
        }
        setSummaryDrillDown({ status: false, data: [] });
        setLocationDrillDown({ status: false, data: [] });
        setImageDrillDown({ status: false, data: [] });

        if (componentConfig.leagueTable) {
          summaryDrillDownFunc({
            fromMenu: true,
            target: {
              dataset: {
                kpiname: "TargetPortfolioCompliance",
                kpidisplayname: `${t("Target Portfolio Compliance", tOpts)}`
              },
              filters: {
                groupBy: "Location.LocationId",
                sort: "DESC"
              }
            }
          });
          summaryDrillDownFunc({
            fromMenu: true,
            target: {
              dataset: {
                kpiname: "TargetPortfolioCompliance",
                kpidisplayname: `${t("Target Portfolio Compliance", tOpts)}`
              },
              filters: {
                groupBy: "Location.LocationId",
                sort: "ASC"
              }
            }
          });
        }
      }
    }
    catch (e) {
      setSummaryData({})
      setSummaryDrillDown({ status: false, data: [] });
      setLocationDrillDown({ status: false, data: [] });
      setImageDrillDown({ status: false, data: [] });
    }
  }, [filterValues])

  async function summaryDrillDownFunc(event) {
    const { target, fromMenu = false } = event;
    if (target.tagName !== "TD" && !fromMenu) {
      return;
    }
    const { kpiname, kpidisplayname } = target.dataset;
    if (!kpiname) {
      return;
    }
    const metricLocal = metricsRef.current.filter((item) => item.dataKey.toLowerCase() === kpiname.toLowerCase());

    if (fromMenu) {
      filterValuesRef.current = { ...filterValuesRef.current, ...target.filters };
    } else {
      delete filterValuesRef.current.sort;
      delete filterValuesRef.current.sortKey;
    }
    const sort = filterValuesRef?.current?.sort;

    const filters = utils.getFilters({
      kpiFilters: filterValuesRef.current || {},
      groupBy: [],
      metrics: metricLocal,
      sort,
      sortKey: sort ? 'WTD' : '',
      countBy: filterValuesRef?.current?.groupBy,
      timeZone: userData?.tags?.NodaTimeZone || ''
    });

    if (filterValuesRef.current.clientIds.length) {
      filters.clientList = filterValuesRef.current.clientIds;
    }

    if (componentConfig.global) {
      filters.action = 'globalReport'
    }

    try {
      filters.clientDates = clientDates;
      filters.activePeriods = (sort && componentConfig.leagueTable) ? ['WTD'] : [...filterValuesRef.current.activePeriods];
      const data = await request({ url: apis.KPISummary, params: filters, history, dispatch, jsonPayload: true });

      if (data) {
        let groupByKey = filterValuesRef?.current?.groupBy, nameKey = groupByKey.replace("Id", "Name");

        nameKey = nameKey.split('.');
        nameKey = nameKey.length > 1 ? nameKey[nameKey.length - 1] : nameKey[0];
        groupByKey = groupByKey.split('.');
        groupByKey = groupByKey.length > 1 ? groupByKey[groupByKey.length - 1] : groupByKey[0];
        summaryDrillDownTableConfig = [
          {
            id: nameKey, label: `${groupByDisplayName[groupByKey]} Name`, minWidth: 200, flex: 0, hideable: false, formatJSX: (item) => {
              if (item.row[groupByKey] === 0 || item.row[groupByKey] == null) {
                return <Tooltip title={`${t(`No ${groupByDisplayName[groupByKey]}`, tOpts)} ${t('is assigned for these records', tOpts)}`} placement="right">
                  <span>{(t("N/A", tOpts))}</span>
                </Tooltip>
              }
              return item.row[nameKey]
            }
          },


        ];

        const sample = data[0];
        for (const key in sample) {
          let label = key;
          for (const period of periods) {
            if (filterValuesRef.current.activePeriods.includes(period.LookupId) && label.includes(`${period.LookupId}_${kpiname}`)) {
              const kpiUnit = kpiUnits[kpiname] || '';
              summaryDrillDownTableConfig.push({
                id: key, label: period.DisplayValue + ` ${kpiUnit.replace('{0}', '')}`, minWidth: 200, flex: 0,
                sortComparator: sortComparator.custom,
                valueGetter: (params) => {
                  return params;
                },
                format: ({ value: item }) => {
                  let kpiValue = formatKPIValue({ kpiName: kpiname, kpiValue: item.row[key], isChestCooler: item.row.IsChestCooler, kpiUnit: kpiUnit, imageCount: Number(item.row[`${period.LookupId}_Images`]) });
                  return kpiValue;
                }
              })
            }
          }
        }

        if (filterValuesRef.current.activePeriods.includes('Custom')) {
          const { fromDate, toDate } = getCustomHeading();
          const keyL = `Custom_${kpiname}`
          summaryDrillDownTableConfig.push({
            id: `Custom_${kpiname}`, label: `${fromDate} to ${toDate}`, minWidth: 200, flex: 0,
            sortComparator: sortComparator.custom,
            valueGetter: (params) => {
              return params;
            },
            format: ({ value: item }) => {
              const kpiUnit = kpiUnits[kpiname] || '',
                kpiValue = formatKPIValue({ kpiName: kpiname, kpiValue: item.row[keyL], isChestCooler: item.row.IsChestCooler, kpiUnit: kpiUnit, imageCount: Number(item.row.Custom_Images) });
              return kpiValue;
            }
          })
        }

        if (filterValuesRef.current.activePeriods.includes('Custom')) {


          summaryDrillDownTableConfig.push({
            id: `Custom_${kpiname}_drillDown`, label: ``, minWidth: 100, flex: 0, sortable: false,
            valueGetter: (params) => {
              return params;
            },

            formatJSX: (params) => {

              return (
                <IconButton className="button-outline"
                  onClick={(e) => onDrillLocation(params, { kpidisplayname, kpiname })}>
                  <AddIcon />
                </IconButton>
              )
            },

          })
        }

        if (!sort) {
          setSummaryDrillDown({ status: true, data: data, kpiDisplayName: kpidisplayname, kpiName: kpiname });
        } else {
          const leagueType = (filters.sort === 'ASC') ? 'Bottom 10' : "Top 10";
          setLeagueSummaryDrillDown(prev => ({
            ...prev,
            [`${kpiname}-${kpidisplayname}-${filters.sort}-${filterValuesRef?.current?.groupBy}`]: {
              sortType: filters.sort,
              data: data,
              kpiDisplayName: kpidisplayname,
              kpiName: kpiname,
              leagueTable: leagueType,
              groupBy: filterValuesRef?.current?.groupBy,
              label: `${kpidisplayname} -  Group by ${groupByDisplayName[filterValuesRef?.current?.groupBy]} - ${leagueType} `,
              summaryDrillDownTableConfig
            }
          }));
        }

        setLocationDrillDown({ status: false, data: [] });
        setImageDrillDown({ status: false, data: [] });
        scrollToBottom();
      }
    }
    catch (e) {
      setSummaryDrillDown({ status: true, data: [] });
      setLocationDrillDown({ status: false, data: [] });
      setImageDrillDown({ status: false, data: [] });
    }
  }

  async function onDrillLocation(event, drillData) {
    const singleKPI = singleKPIRef.current.status,
      groupByKey = filterValuesRef?.current?.groupBy,
      kpiName = singleKPI ? singleKPIRef.current.kpiName : summaryDrillDown.kpiName ? summaryDrillDown.kpiName : drillData.kpiname,
      kpiDisplayName = singleKPI ? singleKPIRef.current.kpiDisplayName : summaryDrillDown.kpiDisplayName ? summaryDrillDown.kpiDisplayName : drillData.kpiDisplayName,
      groupByKeyValue = singleKPI ? event.target.dataset.groupbykeyvalue : event?.row[groupByKey],
      groupByKeyName = singleKPI ? event.target.dataset.groupbykeyname : event?.row[groupByKey.replace(/Id$/, "Name")],
      metricLocal = metricsRef.current.filter((item) => item.dataKey.toLowerCase() === kpiName.toLowerCase());

    const filters = utils.getFilters({
      kpiFilters: filterValuesRef.current || {},
      groupBy: [],
      metrics: metricLocal,
      countBy: 'LocationId',
      timeZone: userData?.tags?.NodaTimeZone || ''
    });

    if (groupByKeyValue === null || groupByKeyValue === undefined) {
      return swal({
        title: 'Can not drill down for Id NULL',
        text: "",
        icon: "info",
        dangerMode: false
      });
    }
    filters.where[groupByKey] = groupByKeyValue;
    try {
      filters.clientDates = clientDates;
      filters.activePeriods = [...filterValuesRef.current.activePeriods];
      const data = await request({ url: apis.KPISummary, params: { ...filters, action: "summaryDrillDown" }, history, dispatch, jsonPayload: true });
      if (data) {
        locationTableConfig = [...tableColumns.locationTableColumns];
        const sample = data[0];
        for (const key in sample) {
          let label = key;
          for (const period of periods) {
            if (filterValuesRef.current.activePeriods.includes(period.LookupId) && label.includes(`${period.LookupId}_${kpiName}`)) {
              const kpiUnit = kpiUnits[kpiName] || '';
              locationTableConfig.push({
                id: key, label: period.DisplayValue + ` ${kpiUnit.replace('{0}', '')}`, minWidth: 100, flex: 0,
                sortComparator: sortComparator.custom,
                valueGetter: (params) => {
                  return params;
                },
                format: ({ value: item }) => {
                  let kpiValue = formatKPIValue({ kpiName: kpiName, kpiValue: item.row[key], isChestCooler: item.row.IsChestCooler, kpiUnit: kpiUnit, imageCount: Number(item.row[`${period.LookupId}_Images`]) });
                  return kpiValue;
                }
              })
            }
          }
        }

        if (filterValuesRef.current.activePeriods.includes('Custom')) {
          const { fromDate, toDate } = getCustomHeading();
          const keyL = `$Custom_${kpiName}`
          locationTableConfig.push({
            id: keyL, label: `${fromDate} to ${toDate}`, minWidth: 200, flex: 0,
            sortComparator: sortComparator.custom,
            valueGetter: (params) => {
              return params;
            },
            format: ({ value: item }) => {
              const kpiUnit = kpiUnits[kpiName] || '',
                kpiValue = formatKPIValue({ kpiName: kpiName, kpiValue: item.row[keyL], isChestCooler: item.row.IsChestCooler, kpiUnit: kpiUnit, imageCount: Number(item.row.Custom_Images) });
              return kpiValue;
            }
          })
        }

        if (filterValuesRef.current.activePeriods.includes('Custom')) {


          locationTableConfig.push({
            id: `Custom_${kpiName}_drillDown`, label: ``, minWidth: 100, flex: 0, sortable: false,

            valueGetter: (params) => {

              return params;
            },

            formatJSX: (params) => {

              return (
                <IconButton className="button-outline"
                  onClick={() => onDrillImage(params, drillData)}
                >
                  <AddIcon />
                </IconButton>
              )
            },

          })
        }




        setLocationDrillDown({
          status: true,
          data: data,
          kpiDisplayName: kpiDisplayName ? kpiDisplayName : drillData.kpidisplayname,
          kpiName: kpiName ? kpiName : drillData.kpiname,
          label: `${kpiDisplayName ? kpiDisplayName : drillData.kpidisplayname} - ${groupByDisplayName[filterValuesRef?.current?.groupBy]} - ${groupByKeyName || t("N/A", tOpts)} - Outlets`
        })
        setImageDrillDown({ status: false, data: [] });
        scrollToBottom();
      }
    }
    catch (e) {
      setLocationDrillDown({ status: true, data: [] });
      setImageDrillDown({ status: false, data: [] });
    }
  }

  async function onDrillImage(event, drillData) {

    const singleKPI = singleKPIRef.current.status,
      locationId = event?.row?.LocationId,
      locationName = event?.row?.Name,
      kpiName = singleKPI ? singleKPIRef.current.kpiName : summaryDrillDown.kpiName ? summaryDrillDown.kpiName : drillData.kpiname,
      kpiDisplayName = singleKPI ? singleKPIRef.current.kpiDisplayName : summaryDrillDown.kpiDisplayName ? summaryDrillDown.kpiDisplayName : drillData.kpiDisplayName,
      metricLocal = metricsRef.current.filter((item) => item.dataKey.toLowerCase() === kpiName.toLowerCase());
    const filters = utils.getFilters({
      kpiFilters: filterValuesRef.current || {},
      groupBy: [],
      metrics: metricLocal,
      countBy: 'AssetId',
      timeZone: userData?.tags?.NodaTimeZone || ''
    });

    if (locationId === null || locationId === undefined) {
      return swal({
        title: 'Can not drill down for Id NULL',
        text: "",
        icon: "info",
        dangerMode: false
      });
    }
    filters.where['AssetPurity.LocationId'] = locationId;
    try {
      filters.clientDates = clientDates;
      filters.activePeriods = [...filterValuesRef.current.activePeriods];
      const data = await request({ url: apis.KPISummary, params: { ...filters, action: "summaryDrillDown" }, history, dispatch, jsonPayload: true });
      if (data) {
        imageTableConfig = [...tableColumns.imageTableColumn];
        const sample = data[0];
        for (const key in sample) {
          let label = key;
          for (const period of periods) {
            if (filterValuesRef.current.activePeriods.includes(period.LookupId) && label.includes(`${period.LookupId}_${kpiName}`)) {
              const kpiUnit = kpiUnits[kpiName] || '';
              imageTableConfig.push({
                id: key, label: period.DisplayValue + ` ${kpiUnit.replace('{0}', '')}`, minWidth: 200, flex: 0,
                sortComparator: sortComparator.custom,
                valueGetter: (params) => {
                  return params;
                },
                format: ({ value: item }) => {
                  let kpiValue = formatKPIValue({ kpiName: kpiName, kpiValue: item.row[key], isChestCooler: item.row.IsChestCooler, kpiUnit: kpiUnit, imageCount: Number(item.row[`${period.LookupId}_Images`]) });
                  return kpiValue;
                }
              })
            }
          }
        }

        if (filterValuesRef.current.activePeriods.includes('Custom')) {
          const { fromDate, toDate } = getCustomHeading();
          const keyL = `$Custom_${kpiName}`
          imageTableConfig.push({
            id: keyL, label: `${fromDate} to ${toDate}`, minWidth: 200, flex: 0,
            sortComparator: sortComparator.custom,
            valueGetter: (params) => {
              return params;
            },
            format: ({ value: item }) => {
              const kpiUnit = kpiUnits[kpiName] || '',
                kpiValue = formatKPIValue({ kpiName: kpiName, kpiValue: item.row[keyL], isChestCooler: item.row.IsChestCooler, kpiUnit: kpiUnit, imageCount: Number(item.row.Custom_Images) });
              return kpiValue;
            }
          })
        }


        setImageDrillDown({
          status: true,
          data: data,
          kpiDisplayName: kpiDisplayName ? kpiDisplayName : drillData.kpidisplayname,
          kpiName: kpiName ? kpiName : drillData.kpiname,
          label: `Images for ${locationName} - ${kpiDisplayName ? kpiDisplayName : drillData.kpidisplayname}`
        })
        scrollToBottom();
      }
    }
    catch (e) {
      setImageDrillDown({ status: true, data: [] });
    }
  }

  async function openVistaCard(event) {
    await getVistaLocationRecord({
      AssetPurityId: event?.row?.AssetPurityId,
      LocationId: event?.row?.LocationId,
      LocationName: event?.row?.Location,
      AssetId: event?.row?.AssetId
    })
  }

  const getVistaLocationRecord = async (data) => {
    const { AssetPurityId, AssetSerialNumber, AssetId, LocationId, LocationName } = data;
    let params = {
      action: "CustomList", asArray: 0, limit: 10, start: 0, showAllImages: false, isFetchCoolerData: true,
      sort: 'AvgStockPercentage', dir: 'ASC'
    };
    const assetPurityIdList = [AssetPurityId];
    params = { ...params, ...{ assetOrLocationName: '', isForRank: true, LocationId: LocationId, assetPurityIds: assetPurityIdList.join() } }
    if (AssetSerialNumber) {
      params.AssetSerialNumber = data.AssetSerialNumber;
    }
    if (AssetId) {
      params.AssetId = AssetId;
    }
    const response = await request({ url: apis.assetLatestImagesNew, params, history, dispatch });

    setOutletCard({
      modal: true,
      data: {
        locationName: LocationName,
        code: '',
        locationId: LocationId,
      },
      defaultTab: 3,
      vistaData: response.records || []
    })
  }

  function getCustomHeading() {
    const toDate = (dayjs().format('MMDDYYYY') === dayjs(filterValuesRef.current.toDate).format('MMDDYYYY')) ? dayjs().subtract(1, 'days').format(utils.systemDateTimeFormat(true)) : dayjs(filterValuesRef.current.toDate).format(utils.systemDateTimeFormat(true));
    const fromDate = dayjs(filterValuesRef.current.fromDate).format(utils.systemDateTimeFormat(true));
    return { fromDate, toDate };
  }

  function prepareDataForTable(data) {
    const defaultConfig = getDefaultTableConfig('KPI'),
      contentData = defaultConfig.contentData,
      headerData = defaultConfig.headersData;

    //handle categories
    let categories = {}
    for (const element of metricsRef.current) {
      if (!categories[element.category]) {
        categories[element.category] = 1;
        continue;
      }
      ++categories[element.category]
    }

    const addedCategory = [];
    for (const element of metricsRef.current) {
      if (element.checked) {
        let category = [];
        if (!addedCategory.includes(element.category)) {
          category.push({
            rowspan: categories[element.category],
            externalStyle: 'text-left',
            isCategory: true,
            displayName: element.category
          })
          addedCategory.push(element.category)
        }
        contentData.push({
          colSpan: 0,
          key: element.dataKey,
          data: [
            ...category,
            {
              colspan: 0,
              externalStyle: 'text-left',
              displayName: element.label,
              definition: kpiDescription[element.dataKey] || element.label,
              customLabel: ({ kpiName, kpiDisplayName, ...rest }) => {
                return <DefaultTableMenu  {...rest} kpiName={kpiName} kpiDisplayName={kpiDisplayName} label={element.label} />
              },
            }
          ]
        })
      }
    }

    if (componentConfig.global) {
      for (let i = 0; i < contentData.length; i++) {
        if (contentData[i].key === kpiNames.salesOpportunity) {
          contentData.splice(i, 1);
        }
      }
    }

    headerData[0].data.unshift({ colspan: 0, label: t(`Sub-Category`, tOpts), hasBorders: true });
    for (const period of periods) {
      if (filterValuesRef.current.activePeriods.includes(period.LookupId)) {
        headerData[0].data.push({ colspan: 0, label: t(`${period.DisplayValue}`, tOpts), hasBorders: true });
      }
    }
    if (filterValuesRef.current.activePeriods.includes('Custom')) {
      const { fromDate, toDate } = getCustomHeading();
      headerData[0].data.push({ colspan: 0, label: t(`${fromDate} to ${toDate}`, tOpts), hasBorders: true });
    }

    let kpiDisplayName = "";
    for (let content of contentData) {
      const items = content.data;
      for (const period of periods) {
        if (filterValuesRef.current.activePeriods.includes(period.LookupId)) {
          items.push({ colspan: 0, key: period.LookupId + "_" + content.key, label: '-' })
        }
      }
      if (filterValuesRef.current.activePeriods.includes('Custom')) {
        items.push({ colspan: 0, key: "Custom_" + content.key, label: '-' })
      }

      let index = 1;
      for (let item of items) {
        if (item.isCategory) {
          item.label = item.displayName;
          continue;
        }
        if (index === 1) {
          kpiDisplayName = item.displayName;
        }
        index++;
        item.kpiName = content.key;
        item.kpiDisplayName = kpiDisplayName;
        if (!item.key) {
          if (item.infoAdded)
            continue;
          item.label = <span className="d-flex ">{item.customLabel ? item.customLabel({ kpiName: item.kpiName, kpiDisplayName: item.kpiDisplayName, onDrillMenu: summaryDrillDownFunc, componentConfig: componentConfig }) : item.displayName}
            <Tooltip title={t(item.definition, tOpts)} placement="right">
              <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
            </Tooltip></span>;
          item.align = 'left'
          item.infoAdded = true;
          continue;
        }
        const kpiUnit = kpiUnits[item.kpiName] || '', imageKey = item.key.split("_")[0] + '_Images',
          kpiValue = data[item.key] ? formatKPIValue({ kpiName: item.kpiName, kpiValue: data[item.key], isChestCooler: data.IsChestCooler, kpiUnit: kpiUnit, imageCount: Number(data[imageKey]) }) : '-';
        item.label = kpiValue;
      }
    }
    setTableConfig(defaultConfig);
  }

  function prepareDataForProgressTable(data) {
    const defaultConfig = getDefaultTableConfig('KPI'),
      contentData = defaultConfig.contentData,
      headerData = defaultConfig.headersData;

    //handle categories
    let categories = {}
    for (const element of metricsRef.current) {
      if (!categories[element.category]) {
        categories[element.category] = 1;
        continue;
      }
      ++categories[element.category]
    }

    if (kpiCategory === 'Merchandising') {
      contentData.push({
        colSpan: 0,
        key: "AssetsCovered",
        data: [
          {
            rowspan: 1,
            externalStyle: 'text-left',
            isCategory: true,
            displayName: t("Connected Assets", tOpts)
          },
          {
            colspan: 0,
            externalStyle: 'text-left',
            displayName: t('Assets Covered', tOpts),
            definition: kpiDescription.AssetsCovered,
            customLabel: ({ kpiName, kpiDisplayName, ...rest }) => {
              return <DefaultTableMenu  {...rest} kpiName={kpiName} kpiDisplayName={kpiDisplayName} label={'Assets Covered'} />
            },
          }
        ]
      })
    }

    const addedCategory = [];
    for (const element of metricsRef.current) {
      let category = [];
      if (!addedCategory.includes(element.category)) {
        category.push({
          rowspan: categories[element.category],
          externalStyle: 'text-left',
          isCategory: true,
          displayName: element.category
        })
        addedCategory.push(element.category)
      }
      if (element.checked) {
        contentData.push({
          colSpan: 0,
          key: element.dataKey,
          data: [
            ...category,
            {
              colspan: 0,
              externalStyle: 'text-left',
              displayName: element.label,
              definition: kpiDescription[element.dataKey] || element.label,
              customLabel: ({ kpiName, kpiDisplayName, ...rest }) => {
                return <DefaultTableMenu  {...rest} kpiName={kpiName} kpiDisplayName={kpiDisplayName} label={element.label} />
              },
            }
          ]
        })
      }
    }

    const chartData = {
      labels: [],
      data: {}
    };
    const { prNPeriod, prPeriod } = filterValuesRef.current || {};

    headerData[0].data.unshift({ colspan: 0, label: t(`Sub-Category`, tOpts), hasBorders: true });
    for (let i = Number(prNPeriod); i >= 0; i--) {
      let headerLabel = i === 0 ? 'Current' : `${prPeriod} -${i}`;

      if (prPeriod === 'Week') {
        headerLabel = dayjs(prGridParams.prParameters['StartW' + i].value).week();
        headerLabel = `${t('Week', tOpts)} ${headerLabel}`
      } else if (prPeriod === 'Month') {
        headerLabel = dayjs(prGridParams.prParameters['StartW' + i].value).format('MMM YYYY');
      } else if (prPeriod === 'Quarter') {
        const quarterNo = dayjs(prGridParams.prParameters['StartW' + i].value).quarter();
        const yearNo = dayjs(prGridParams.prParameters['StartW' + i].value).format('YY');
        headerLabel = `Q${quarterNo} - ${yearNo}`
      } else if (prPeriod === 'Year') {
        headerLabel = dayjs(prGridParams.prParameters['StartW' + i].value).format('YYYY')
      }

      chartData.labels.push(headerLabel);
      headerData[0].data.push({
        colspan: 0, label: <div>
          <span>
            {t(headerLabel, tOpts)}
          </span>
          <br />
          {(prPeriod === 'Week') ? <small>
            <span>
              {`${dayjs(prGridParams.prParameters['StartW' + i].value).format(dateFormat.monthSingle)} - ${(i === 0 ? dayjs(prGridParams.prParameters['EndW' + i].value).subtract(1, 'day') : dayjs(prGridParams.prParameters['EndW' + i].value)).format(dateFormat.monthSingle)}`}
            </span>
          </small> : ''}
        </div>, hasBorders: true
      })
    }
    headerData[0].data.push({
      colspan: 0, label: <><p className="mb-0">{t("Difference", tOpts)}</p><p className="mb-0">{t("from start", tOpts)}</p></>, hasBorders: true
    })

    let kpiDisplayName = "", prevValue = {}, valueMap = { firstValue: {}, lastValue: {} };
    for (let content of contentData) {
      for (let i = Number(prNPeriod); i >= 0; i--) {
        content.data.push({ colspan: 0, key: `${prPeriod}_${i}_${content.key}`, label: "-" })
      }
      let index = 0;
      for (let item of content.data) {
        if (item.isCategory) {
          item.label = item.displayName;
          continue;
        }
        if (index === 0) {
          kpiDisplayName = item.displayName;
        }
        item.kpiName = content.key;
        item.kpiDisplayName = kpiDisplayName;
        if (!chartData.data[item.kpiDisplayName]) {
          chartData.data[item.kpiDisplayName] = [];
        }
        let imageKey;
        if (item.key) {
          imageKey = item.key.split("_");
          imageKey = imageKey[0] + "_" + imageKey[1] + '_Images';
          const formattedValue = formatKPIValue({ kpiName: item.kpiName, kpiValue: data[item.key], prevKpiValue: 0, isChestCooler: data.IsChestCooler, kpiUnit: '', imageCount: Number(data[imageKey]), progressReport: false });
          chartData.data[item.kpiDisplayName] = [...chartData.data[item.kpiDisplayName], formattedValue]
        }
        if (!item.key) {
          if (item.infoAdded)
            continue;
          item.label = <span className="d-flex ">{item.customLabel ? item.customLabel({ kpiName: item.kpiName, kpiDisplayName: item.kpiDisplayName, onDrillMenu: summaryDrillDownFunc, componentConfig: componentConfig }) : item.displayName}
            <Tooltip title={t(item.definition, tOpts)} placement="right">
              <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
            </Tooltip></span>;
          item.infoAdded = true;
          index++;
          continue;
        }
        const kpiUnit = kpiUnits[item.kpiName] || '',
          kpiValue = formatKPIValue({ kpiName: item.kpiName, kpiValue: data[item.key], prevKpiValue: prevValue[item.kpiName], isChestCooler: data.IsChestCooler, kpiUnit: kpiUnit, imageCount: Number(data[imageKey]), progressReport: true });
        item.label = kpiValue;
        prevValue[item.kpiName] = Number(data[imageKey]) === 0 ? undefined : data[item.key]
        if (!valueMap.firstValue.hasOwnProperty(item.kpiName) && (data[item.key] !== null) && (data[imageKey] > 0)) {
          const value = item.kpiName === kpiNames.assetsCovered ? Math.ceil(data[item.key]) : data[item.key];
          valueMap.firstValue[item.kpiName] = value;
        }
        if (index === (Number(prNPeriod) + 1)) {
          const value = item.kpiName === kpiNames.assetsCovered ? Math.ceil(data[item.key]) : data[item.key];
          valueMap.lastValue[item.kpiName] = (data[imageKey] > 0) ? value : 0;
        }
        index++;
      }

      let variation = (content.key === kpiNames.salesOpportunity || content.key === kpiNames.valueSuggested) ?
        Math.round(Number(valueMap.firstValue[content.key])) - Math.round(Number(valueMap.lastValue[content.key])) :
        Number(valueMap.firstValue[content.key]).toFixed(1) - Number(valueMap.lastValue[content.key]).toFixed(1);
      variation = formatKPIValue({ kpiName: content.key, kpiValue: (variation * -1), prevKpiValue: 0, isChestCooler: data.IsChestCooler, kpiUnit: kpiUnits[content.key], imageCount: 1, progressReport: true });
      content.data.push({ colspan: 0, key: 'variation', label: variation, cellColor: '#fafafa' })
    }
    const chartKey = "PC_" + (Math.floor(Date.now() / 1000));
    setChartInfo({ chartData, chartKey })
    setTableConfig(defaultConfig);
  }

  function prepareDataForSingleKPI({ data }) {
    const { groupBy, activePeriods } = filterValuesRef.current;
    const defaultConfig = getDefaultTableConfig(groupByDisplayName[groupBy]),
      contentData = defaultConfig.contentData,
      headerData = defaultConfig.headersData,
      groupBYName = groupBy.replace('Id', 'Name'),
      kpiName = singleKPIRef.current.kpiName,
      kpiDisplayName = singleKPIRef.current.kpiDisplayName;

    for (const period of periods) {
      if (filterValuesRef.current.activePeriods.includes(period.LookupId)) {
        headerData[0].data.push({ colspan: 0, label: `${period.DisplayValue}`, hasBorders: true });
      }
    }
    if (filterValuesRef.current.activePeriods.includes('Custom')) {
      const { fromDate, toDate } = getCustomHeading();
      headerData[0].data.push({ colspan: 0, label: `${fromDate} to ${toDate}`, hasBorders: true });
    }
    // headerData[0].data.push({ colspan: 0, label: "Variation", hasBorders: true });

    for (const obj of data) {
      let kpiValue = obj[`${activePeriods[0]}_${kpiName}`];
      kpiValue = formatKPIValue({ kpiName: kpiName, kpiValue: kpiValue, isChestCooler: obj.IsChestCooler, kpiUnit: kpiUnits[kpiName], imageCount: obj[`${activePeriods[0]}_Images`] })
      const groupByKeyValue = obj[groupBy], groupByKeyName = obj[groupBYName];

      contentData.push({
        colSpan: 0,
        key: obj[groupBYName],
        data: [
          {
            colspan: 0,
            externalStyle: 'text-left',
            label: obj[groupBYName] || 'N/A',
            kpiName: kpiName,
            kpiDisplayName: kpiDisplayName,
            groupByKeyValue: groupByKeyValue,
            groupByKeyName: groupByKeyName,
          },
          {
            colspan: 0,
            label: kpiValue,
            kpiName: kpiName,
            kpiDisplayName: kpiDisplayName,
            groupByKeyValue: groupByKeyValue,
            groupByKeyName: groupByKeyName,
          }
        ]
      })
    }
    setTableConfig(defaultConfig);
  }

  function prepareDataForSingleKPIProgress(props) {
    const { data, prParameters } = props;
    const { groupBy, prNPeriod, prPeriod } = filterValuesRef.current;
    const defaultConfig = getDefaultTableConfig(groupByDisplayName[groupBy]),
      contentData = defaultConfig.contentData,
      headerData = defaultConfig.headersData,
      groupBYName = groupBy.replace('Id', 'Name'),
      kpiName = singleKPIRef.current.kpiName,
      chartData = { labels: [], data: {} };

    for (let i = Number(prNPeriod); i >= 0; i--) {
      let headerLabel = i === 0 ? 'Current' : `${prPeriod} -${i}`;
      if (prPeriod === 'Week') {
        headerLabel = dayjs(prParameters['StartW' + i].value).week();
        headerLabel = `${t('Week', tOpts)} ${headerLabel}`
      } else if (prPeriod === 'Month') {
        headerLabel = dayjs(prParameters['StartW' + i].value).format('MMM YYYY');
      } else if (prPeriod === 'Quarter') {
        const quarterNo = dayjs(prParameters['StartW' + i].value).quarter();
        const yearNo = dayjs(prParameters['StartW' + i].value).format('YY');
        headerLabel = `Q${quarterNo} - ${yearNo}`
      } else if (prPeriod === 'Year') {
        headerLabel = dayjs(prParameters['StartW' + i].value).format('YYYY')
      }

      chartData.labels.push(headerLabel);
      headerData[0].data.push({
        colspan: 0, label: <div>
          <span>
            {t(headerLabel, tOpts)}
          </span>
          <br />
          {(prPeriod === 'Week') && <small>
            <span>
              {`${dayjs(prParameters['StartW' + i].value).format(dateFormat.monthSingle)} - ${(i === 0 ? dayjs(prParameters['EndW' + i].value).subtract(1, 'day') : dayjs(prParameters['EndW' + i].value)).format(dateFormat.monthSingle)}`}
            </span>
          </small>}
        </div>, hasBorders: true
      })
    }
    headerData[0].data.push({
      colspan: 0, label: <><p className="mb-0">{t("Difference", tOpts)}</p><p className="mb-0">{t("from start", tOpts)}</p></>, hasBorders: true
    })

    const valueMap = { firstValue: {}, lastValue: {} };
    for (const obj of data) {
      const tempData = [{
        colspan: 0,
        externalStyle: 'text-left',
        displayName: '',
        label: obj[groupBYName] || 'N/A',
      }], tempChartData = [];

      for (let i = Number(prNPeriod); i >= 0; i--) {
        const key = `${prPeriod}_${i}_${kpiName}`, imageKey = `${prPeriod}_${i}_Images`;
        let kpiValue = obj[key], prevValue = obj[imageKey] === 0 ? undefined : i === Number(prNPeriod) ? obj[key] : obj[`${prPeriod}_${i + 1}_${kpiName}`];
        const chartValue = formatKPIValue({ kpiName: kpiName, kpiValue: kpiValue, prevKpiValue: 0, isChestCooler: obj.IsChestCooler, kpiUnit: '', imageCount: obj[imageKey], progressReport: false });
        tempChartData.push(chartValue);
        kpiValue = formatKPIValue({ kpiName: kpiName, kpiValue: kpiValue, prevKpiValue: prevValue, isChestCooler: obj.IsChestCooler, kpiUnit: kpiUnits[kpiName], imageCount: obj[imageKey], progressReport: true });
        tempData.push({ colspan: 0, key: key, label: kpiValue })

        if (!valueMap.firstValue.hasOwnProperty(obj[groupBy]) && (obj[key] !== null) && (obj[imageKey] > 0)) {
          const value = kpiName === kpiNames.assetsCovered ? Math.ceil(obj[key]) : obj[key];
          valueMap.firstValue[obj[groupBy]] = value;
        }
        if (i === 0) {
          const value = kpiName === kpiNames.assetsCovered ? Math.ceil(obj[key]) : obj[key];
          valueMap.lastValue[obj[groupBy]] = (obj[imageKey] > 0) ? value : 0;
        }
      }

      let diffFromStart = (kpiName === kpiNames.salesOpportunity || kpiName === kpiNames.valueSuggested) ?
        Math.round(Number(valueMap.lastValue[obj[groupBy]])) - Math.round(Number(valueMap.firstValue[obj[groupBy]])) :
        Number(valueMap.lastValue[obj[groupBy]]).toFixed(1) - Number(valueMap.firstValue[obj[groupBy]]).toFixed(1);
      diffFromStart = formatKPIValue({ kpiName: kpiName, kpiValue: diffFromStart, prevKpiValue: 0, isChestCooler: obj.IsChestCooler, kpiUnit: kpiUnits[kpiName], imageCount: 1, progressReport: true });
      tempData.push({ colspan: 0, key: 'Variation', label: diffFromStart, cellColor: '#fafafa' })
      contentData.push({
        colSpan: 0,
        key: obj[groupBYName],
        data: tempData
      })
      chartData.data[obj[groupBYName]] = tempChartData;
    }
    const chartKey = "PCSK_" + (Math.floor(Date.now() / 1000));
    setChartInfo({ chartData, chartKey })
    setTableConfig(defaultConfig);
  }

  const { SalesPerson = [] } = comboData;

  function formatKPIValue({ kpiName, kpiValue, isChestCooler, kpiUnit = '', imageCount, prevKpiValue, progressReport }) {
    let transition = 'neutral';

    if (progressReport && prevKpiValue !== undefined) {
      const diff = Number(prevKpiValue) - Number(kpiValue);
      if (diff === 0) {
        transition = 'neutral'
      } else if (diff > 0) {
        transition = 'down';
      } else if (diff < 0) {
        transition = 'up';
      }
    }

    let kpiValueL = kpiValue === null ? '-' :
      (kpiName === kpiNames.salesOpportunity || kpiName === kpiNames.valueSuggested) ? utils.getCurrencyFormat(Number(kpiValue || 0)) :
        (kpiName === kpiNames.planogramCompliance && !isPlanogramCompliance) ? '-' :
          kpiName === kpiNames.assetsCovered ? Math.ceil(Number(kpiValue || 0)) :
            Number(kpiValue || 0).toFixed(1);
    if (kpiName === kpiNames.doorOpenCount && isChestCooler === 1 && kpiValueL === '0.0') {
      kpiValueL = '-';
    }
    kpiValueL = imageCount === 0 ? '-' : kpiUnit.includes('{0}') ? kpiUnit.replace('{0}', kpiValueL) : kpiValueL;
    if (kpiValueL === '-') {
      transition = 'neutral';
    } else {
      kpiValueL = kpiValueL + kpiUnit;
    }
    if (progressReport) {
      kpiValueL = <div><span>{kpiValueL}</span><span>{progressIcons[transition]}</span></div>;
    }
    return kpiValueL;
  }

  function formatClientsList(list) {
    list = list.sort((a, b) => a.Value.localeCompare(b.Value));
    return list.map((item) => { return { LookupId: (item.Key || item.LookupId), DisplayValue: item.Value } });
  }

  const filtersConfig = [
    {
      name: "Filters",
      visible: true,
      config: [
        {
          name: "clientIds",
          label: "Client",
          type: "select",
          multiSelect: true,
          checkUncheck: true,
          options: formatClientsList(clientsList),
          size: 'small',
          hidden: componentConfig.global ? false : true
        },
        {
          name: "marketIds",
          label: "Market",
          type: "select",
          multiSelect: true,
          checkUncheck: true,
          options: localCombo.Market,
          size: 'small',
          hidden: componentConfig.global ? true : false,
        },
        {
          name: "classificationIds",
          label: "Classification",
          type: "select",
          multiSelect: true,
          checkUncheck: true,
          options: localCombo.LocationClassification,
          size: 'small',
          hidden: componentConfig.global ? true : false
        },
        {
          name: "channelIds",
          label: "Channel",
          type: "select",
          multiSelect: true,
          checkUncheck: true,
          options: localCombo.LocationType,
          size: 'small',
          hidden: componentConfig.global ? true : false
        },
        {
          name: "distributorIds",
          label: "Distributor",
          type: "select",
          multiSelect: true,
          checkUncheck: true,
          options: localCombo.Distributor || [],
          size: 'small',
          hidden: componentConfig.global ? true : false
        },
        {
          name: "managerSelectedValue",
          label: "Primary Sales Rep",
          type: "select",
          multiSelect: true,
          checkUncheck: true,
          options: SalesPerson,
          size: 'small',
          hidden: componentConfig.global ? true : false
        },
      ],
      openDefault: true
    },
  ]

  function onLeagueTableClose(key) {
    let leagueTables = leagueSummaryDrillDown;
    delete leagueTables[key];
    setLeagueSummaryDrillDown({ ...leagueTables })
  }

  const handleChangeTab = (event, newValue) => {
    if (newValue === activeTab) {
      return;
    }

    // Clear old data
    clearFilters();
    setTableConfig({});
    setSummaryData({});
    setChartData({ chartData: {}, chartKey: ('summaryChartKey' + Math.floor(Date.now() / 1000)) });
    setSummaryDrillDown({ status: false, data: [] });
    setLocationDrillDown({ status: false, data: [] });
    setImageDrillDown({ status: false, data: [] });
    singleKPIRef.current = { status: false }
    // Change tab
    setActiveTab(newValue);
    setComponentConfig(tabConfig[newValue + '']);
    // recall the api
    setLoading({ filter: true, api: false })
  };

  function progressReport(singleKPI) {

    const filterConfig = [
      [{
        name: "selectedMetrics",
        label: "KPI",
        type: "select",
        multiSelect: false,
        checkUncheck: false,
        hideMenuLabel: true,
        options: metricOptionsIB,
        size: 'small',
        hidden: false
      }, {
        name: "groupBy",
        label: "Group By",
        type: "select",
        multiSelect: false,
        checkUncheck: false,
        options: groupByOptionsIB,
        size: 'small',
        hidden: false,
        disabled: !singleKPI,
        hideMenuLabel: true,
      }], [{
        name: "prPeriod",
        label: "Period",
        type: "select",
        multiSelect: false,
        checkUncheck: false,
        options: prPeriodOptions,
        size: 'small',
        hidden: false,
        hideMenuLabel: true,
      }, {
        name: "prNPeriod",
        label: "Last",
        type: "select",
        multiSelect: false,
        checkUncheck: false,
        hideMenuLabel: true,
        options: prNPeriodOptions,
        size: 'small',
        hidden: false
      }]]

    return (<>
      <Card className="transparent-bg mt-2 mb-2">
        {/* Info bar */}
        <InfoBar t={t} tOpts={tOpts} infoBarData={infoBarData} filterConfig={filterConfig} />
        {/* Progress Charts */}
        <Grid container spacing={2} className="mb-1">
          <Grid id='summary-table-grid' item xs={12} sm={12} md={12} lg={12}>
            {Object.keys(chartInfo.chartData).length ? <>
              <Paper elevation={2} sx={{ maxWidth: 500 }} className="pl-3 pr-3 pt-4 pb-4 mt-2">
                {singleKPI ?
                  <ProgressChartsSingleKPI key={chartInfo.chartKey} data={chartInfo.chartData} /> :
                  <ProgressCharts key={chartInfo.chartKey} data={chartInfo.chartData} />}
              </Paper>
            </> : null}
          </Grid>
        </Grid>
        {/* Summary table */}
        <Grid container spacing={2} className="mb-1">
          <Grid id='summary-table-grid' item xs={12} sm={12} md={12} lg={12}>
            <Card className="mt-2 py-3">
              <CardContent className="p-3">
                {(Object.keys(tableConfig).length > 0) ?
                  <SummaryTable tableConfig={tableConfig} borderOnIndex={1} /> :
                  <div className="text-center">{<h4>{loaderOpen ? t("Loading Grid Data", tOpts) : t("No Data", tOpts)}</h4>}</div>}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </>)
  }

  function drillDownReport(singleKPI) {

    const filterConfig = [
      [{
        name: "selectedMetrics",
        label: "KPI",
        type: "select",
        multiSelect: false,
        checkUncheck: false,
        hideMenuLabel: true,
        options: metricOptionsIB,
        size: 'small',
        hidden: false
      }, {
        name: "groupBy",
        label: "Group By",
        type: "select",
        multiSelect: false,
        checkUncheck: false,
        options: groupByOptionsIB,
        size: 'small',
        hidden: false,
        hideMenuLabel: true,
      }], [{
        name: "date",
        type: "date",
        size: 'small',
        maxDate: dateFilterMaxDate,
        hidden: false,
        hideMenuLabel: true,
      }]]

    return (<>
      <Card className="transparent-bg mt-2">
        {/* Info bar */}
        <InfoBar t={t} tOpts={tOpts} infoBarData={infoBarData} filterConfig={filterConfig} dateFilterMaxDate={dateFilterMaxDate} />
        <Grid container spacing={2} className={mobile.mobile && !mobile.tablet ? "mb-1" : "mb-1 mt2"}>
          <Grid item xs={1} sm={1} md={2} lg={2}> </Grid>
          <Grid item xs={12} sm={mobile.mobile && !mobile.tablet ? 12 : 10} md={8} lg={8}>
            {/* Summary table */}
            <Grid container spacing={2} className={mobile.mobile && !mobile.tablet ? "mb-1" : "mt-2 mb-1"}>
              <Grid id='summary-table-grid' item xs={12} sm={12} md={12} lg={12}>
                <Card className={mobile.mobile && !mobile.tablet ? "py-3" : "mt-2 py-3"}>
                  <CardContent className="p-3">
                    {(Object.keys(tableConfig).length > 0) ?
                      <SummaryTable tableConfig={tableConfig} borderOnIndex={1} onDrillDown={singleKPI ? onDrillLocation : summaryDrillDownFunc} onDrillMenu={singleKPI ? onDrillLocation : summaryDrillDownFunc} selectedKPI={summaryDrillDown.kpiName} /> :
                      <div className="text-center">{<h4>{loaderOpen ? t("Loading Grid Data", tOpts) : t("No Data", tOpts)}</h4>}</div>}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {/* Drill down group by */}
            <Grid container spacing={2} className="mb-1">
              <Grid id='summary-table-grid' item xs={12} sm={12} md={12} lg={12}>
                {summaryDrillDown.status ? <>
                  <Paper elevation={2} sx={{ maxWidth: 500 }} className="pl-3 pr-3 pb-3 mt-2">
                    <Grid container className="pt-2 pb-1">
                      <Grid item xs={10} sm={10} md={10} lg={10}>

                        <span className="hd-title">{`${t(summaryDrillDown.kpiDisplayName, tOpts)} - ${t(groupByDisplayName[filterValuesRef?.current?.groupBy], tOpts)}`}</span>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2} className="text-align-right">
                        <CloseIcon onClick={() => { setSummaryDrillDown({ status: false, data: [] }); }} className="no-print icon-search cursor_pointer" />
                      </Grid>
                    </Grid>
                    {componentConfig.global && <div className="mb-5" >
                      <GlobalCharts data={summaryDrillDown.data} helper={{
                        periods: periods,
                        activePeriods: filterValuesRef.current.activePeriods,
                        kpiName: summaryDrillDown.kpiName,
                        customColumn: customColumnRef.current,
                        getCustomHeading: getCustomHeading
                      }}
                        sortModal={sortModal} />
                    </div>}

                    <GridBase
                      customClass={'data-grid-font-12'}
                      columns={summaryDrillDownTableConfig}
                      data={summaryDrillDown.data}
                      onCellDoubleClick={onDrillLocation}
                      onSortModelChange={onSortModelChange}
                      otherOptions={{ disableSelectionOnClick: true, density: "compact" }}
                      disableJTP={true} />
                  </Paper>
                </> : null}
              </Grid>
            </Grid>
            {/* Location drill down */}
            {locationDrillDown.status ? <>
              <Grid container spacing={2} className="mb-1">
                <Grid id='summary-table-grid' item xs={12} sm={12} md={12} lg={12}>
                  <Paper elevation={2} sx={{ maxWidth: 500 }} className="pl-3 pr-3 pb-3 mt-2">
                    <Grid container className="pt-2 pb-1">
                      <Grid item xs={10} sm={10} md={10} lg={10}>
                        <span className="hd-title">{t(locationDrillDown.label, tOpts)}</span>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2} className="text-align-right">
                        <CloseIcon onClick={() => { setLocationDrillDown({ status: false, data: [] }); }} className="no-print icon-search cursor_pointer" />
                      </Grid>
                    </Grid>
                    <GridBase
                      customClass={'data-grid-font-12'}
                      columns={locationTableConfig}
                      data={locationDrillDown.data}
                      onCellDoubleClick={onDrillImage}
                      onSortModelChange={onSortModelChange}
                      otherOptions={{ disableSelectionOnClick: true, density: "compact" }}
                      disableJTP={true} />
                  </Paper>
                </Grid>
              </Grid>
            </> : null}

            {/* Image drill down */}
            <Grid container spacing={2} className="mb-1">
              <Grid id='summary-table-grid' item xs={12} sm={12} md={12} lg={12}>
                {imageDrillDown.status ? <>
                  <Paper elevation={2} sx={{ maxWidth: 500 }} className="kpi-cards pl-3 pr-3 pb-3 mt-2">
                    <Grid container className="pt-2 pb-1">
                      <Grid xs={10} sm={10} md={10} lg={10}>
                        <span className="hd-title">{t(imageDrillDown.label, tOpts)}</span>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2} className="text-align-right">
                        <CloseIcon onClick={() => { setImageDrillDown({ status: false, data: [] }); }} className="no-print icon-search cursor_pointer" />
                      </Grid>
                    </Grid>
                    <GridBase
                      customClass={'data-grid-font-12'}
                      columns={imageTableConfig}
                      data={imageDrillDown.data}
                      onCellDoubleClick={openVistaCard}
                      onSortModelChange={onSortModelChange}
                      otherOptions={{ disableSelectionOnClick: true, density: "compact" }}
                      disableJTP={true} />
                  </Paper>
                </> : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={2} lg={2}> </Grid>
        </Grid>
      </Card>
    </>)
  }

  const singleKPI = singleKPIRef.current.status;
  return <>
    {/* Tabs Header */}
    <Card className={`mb-1 p-0  ${!mobile.mobile ? "merchandising-subheader-gap" : (!mobile.tablet ? (mobile.portrait ? "mt-2" : "mt-0") : (mobile.tablet && mobile.portrait ? "merchandising-subheader-gap" : "mt-0"))}`} >
      <CardContent className="m-0 p-0 ">
        <Tabs value={activeTab} onChange={handleChangeTab} centered variant="fullWidth">
          <Tab icon={<img src={require("../../assets/images/progress.png")} height='30' alt="" />} iconPosition="start" label={t("Progress Report", tOpts)} />
          <Tab icon={<img src={require("../../assets/images/drillDown.png")} height='30' alt="" />} iconPosition="start" label={t("Asset Drill Down", tOpts)} />
        </Tabs>
      </CardContent>
    </Card>

    {/* Tabs body */}
    <div>
      {activeTab === 0 ? progressReport(singleKPI) : activeTab === 1 ? drillDownReport(singleKPI) : <>{t('No Data, Kindly select a Tab', tOpts)}</>}
    </div>

    {/* League table */}
    {(Object.keys(leagueSummaryDrillDown).length > 0) &&
      <Grid container className="kpi-cards mt-3">
        {Object.keys(leagueSummaryDrillDown).map((key, index) => {
          const league = leagueSummaryDrillDown[key];
          return <Grid item xs={6} sm={6} md={6} lg={6} key={key} className={(((index + 1) % 2) === 0) ? 'leaguePL' : 'leaguePR'}>
            <Paper elevation={2} className={`pl-3 pr-3 pb-3 mt-2 league${league.sortType}`}>
              <Grid container>
                <Grid className="mt-3 mb-3" item xs={10} sm={10} md={10} lg={10}>
                  <span className="hd-title mt-3">{t(league.label, tOpts)}</span>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} className="text-align-right">
                  <CloseIcon onClick={() => { onLeagueTableClose(key); }} className="no-print icon-search cursor_pointer" />
                </Grid>
              </Grid>
              <GridBase
                customClass={'data-grid-font-12'}
                columns={league.summaryDrillDownTableConfig}
                data={league.data}
                pagination={false}
                onSortModelChange={onSortModelChange}
                otherOptions={{ disableSelectionOnClick: true, density: "compact" }}
                disableJTP={true} />
            </Paper>
          </Grid>
        })}
      </Grid>}

    {/* Outlet card */}
    {outletCard.modal && <OutletCard outletCard={outletCard} setOutletCard={setOutletCard} defaultTab={outletCard.defaultTab} customClass='edit-order-mobile-overrides' />}

  </>
}


export default withTranslation()(React.memo(Merchandising))